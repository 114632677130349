import { AxiosError } from 'axios';

export type ErrorLikeType = Error | string | null;

export type HasErrorValue = string | boolean | string[];
type HasErrorMap = Record<string, HasErrorValue>;

export function errorToMessage (error: Error, fallbackMessage = 'Okänt fel'): string {
  let message: string = error.message;

  if ((error as AxiosError).isAxiosError) {
    message = axiosErrorToMessage(error as AxiosError);
  }

  if (typeof message === 'string' && message.length > 0) {
    return message;
  }

  return fallbackMessage;
}

// attempts to extract a simple error message string from a backend AxiosError
export function axiosErrorToMessage (axiosError: AxiosError): string {
  const responseData = axiosError.response?.data;

  if (typeof responseData === 'string') {
    try {
      return JSON.parse(responseData);
    } catch (err) { // eslint-ignore-line empty
    }
  }

  if (typeof responseData?.exception === 'string') {
    // we have a laravel error...
    return laravelErrorToMessage(responseData as ILaravelError);
  }

  if (typeof responseData?.message === 'string') {
    return responseData.message;
  }

  if (typeof responseData?.error === 'string') {
    return responseData.error;
  }

  return axiosError.message;
}

interface ILaravelErrorTrace {
  class: string;
  file: string;
  function: string;
  line: number;
  type: string;
}

interface ILaravelError {
  exception: string;
  file: string;
  line: number;
  message: string;
  trace: ILaravelErrorTrace[];
}

export function laravelErrorToMessage (laravelError: ILaravelError): string {
  const { message, exception } = laravelError;
  if (message) return message;
  if (exception) return exception;
  return 'Okänt fel från Laravel';
}

export function maybeAxiosErrorToErrorMap (axiosError: AxiosError): HasErrorMap | null {
  const status = axiosError.response?.status;

  // response body looks like this for errors, when using laravel Request classes
  if (axiosError?.response?.data?.errors) {
    return axiosError?.response?.data?.errors as HasErrorMap;
  }

  // TODO, for now, we assume only status 400 can return validation errors
  // in the future we should probably designate a special response key in the server instead
  // of relying on a specific status code
  if (status !== 400) return null;
  return axiosError.response?.data as HasErrorMap;
}
