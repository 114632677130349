import React, { useMemo, useState, useId, useRef, useCallback } from 'react';
import { AsyncTypeahead, TypeaheadComponentProps } from 'react-bootstrap-typeahead';
import Typeahead from 'react-bootstrap-typeahead/types/core/Typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { CompaniesSearchDTO, useCompanySearch } from 'client/components/OrgNumberInput';
import { Option } from 'react-bootstrap-typeahead/types/types';
import { FilterFieldValue } from 'client/hooks/useTableState';
import useUpdateEffect from '@restart/hooks/useUpdateEffect';
import useMergedRefs from '@restart/hooks/useMergedRefs';
import useUpdatedRef from '@restart/hooks/useUpdatedRef';

interface CompanySearchProps {
  onSelect: (orgNumber: string) => void;
  onInputChange?: (query: string) => void;
  onSearch?: (query: string) => void;
  defaultInputValue?: FilterFieldValue;
  className?: string;
  searchUrl?: string;
  disabled?: boolean;
  isValid?: boolean;
}

export default React.memo(React.forwardRef<Typeahead, CompanySearchProps>(function CompanySearch (props, outerRef) {
  const {
    onSelect,
    disabled,
    className,
    searchUrl,
    defaultInputValue = '',
    onInputChange:outerOnInputChange,
    onSearch:outerOnSearch,
    isValid,
  } = props;

  const innerRef = useRef<Typeahead>();
  const ref = useMergedRefs(outerRef as typeof innerRef, innerRef);
  const id = useId();
  const [inputValue, setInputValue] = useState(() => defaultInputValue ?? '');

  const { data: searchResults, isFetching } = useCompanySearch({ 
    search: inputValue,
    url: searchUrl,
  });

  const onSelectNone = () => {
    onSelect?.('');
  };

  useUpdateEffect(() => {
    if (isFetching) return;
    if (!searchResults || searchResults.length === 0) {
      onSelectNone();
    } else if (searchResults) {
      const selected = searchResults.find(c => c.org_number === inputValue);
      if (selected && selected.org_number !== defaultInputValue) {
        onSelect?.(selected.org_number);
      }
    }
  }, [searchResults, isFetching]);

  const onPaste = (ev: React.ClipboardEvent<HTMLInputElement>) => {
    let text = ev.clipboardData.getData('text');
    // if the users pastes a string of numbers and dashes, we remove all dashes
    if (/^[-\d\s]+$/.test(text)) {
      ev.preventDefault();
      text = text.replace(/\D/g, '');
      ev.currentTarget.value = text;

      innerRef.current?.setState({
        text,
        selected: [],
      });
      // onSearch is not called in this case, so setValue manually
      onSearch(text);
    }
  };

  const onChange = (selected: CompaniesSearchDTO[] | TypeaheadComponentProps['options']) => {
    const option = selected[0];
    if (!option || typeof option === 'string') return;
    onSelect?.(option.org_number);
  };

  const onSearch = (query: string) => {
    setInputValue(query);
    outerOnSearch?.(query);
  };

  const onInputChange = (query: string) => {
    if (!query || query === '') onSelectNone();
    outerOnInputChange?.(query);
  };

  const inputProps = {
    onPaste,
    name: 'org_number',
  };

  return (
    <AsyncTypeahead
      ref={ref}
      id={id}
      delay={200}
      className={className}
      filterBy={() => true}
      useCache={false}
      labelKey="org_number"
      disabled={disabled}
      searchText="Söker…"
      placeholder="Sök på orgnr/namn"
      promptText="Skriv något för att söka"
      emptyLabel="Inga sökresultat"
      options={searchResults || []}
      isLoading={isFetching}
      onChange={onChange}
      onSearch={onSearch}
      onInputChange={onInputChange}
      defaultInputValue={defaultInputValue ?? ''}
      isValid={!isFetching && isValid}
      renderMenuItemChildren={MenuItemChildren}
      inputProps={inputProps}
      highlightOnlyResult
    />
  );
}));

const menuItemStyle = {fontWeight: 500};

function MenuItemChildren (props: Option) {
  return (
    <div className="py-1">
      <div style={menuItemStyle}>{(props as CompaniesSearchDTO).org_number}</div>
      <div>{(props as CompaniesSearchDTO).company_name}</div>
    </div>
  );
}

type ManagedProps = Pick<CompanySearchProps, 'isValid' | 'onSelect' | 'onSearch'>;

interface UseCompanySearchManagerProps extends Partial<ManagedProps> { }

interface UseCompanySearchManager {
  clear: () => void;
  setValue: (value: string) => void;
  props: ManagedProps & {
    ref: React.MutableRefObject<Typeahead | null>;
  }
}

export function useCompanySearchManager (props: UseCompanySearchManagerProps = {}): UseCompanySearchManager {
  const { isValid:outerIsValid, onSelect:outerOnSelect, onSearch:outerOnSearch } = props;
  const [isValid, setIsValid] = useState(Boolean(outerIsValid));
  const ref = useRef<Typeahead>(null);

  const outerOnSelectRef = useUpdatedRef(outerOnSelect);
  const outerOnSearchRef = useUpdatedRef(outerOnSearch);

  const onSelect = useCallback((orgNumber: string) => {
    setIsValid(Boolean(orgNumber));
    outerOnSelectRef.current?.(orgNumber);
  }, [setIsValid]);

  const onSearch = useCallback((query: string) => {
    setIsValid(false);
    outerOnSearchRef.current?.(query);
  }, [setIsValid]);

  const setValue = useCallback((value: string) => {
    ref.current?.setState({
      text: value,
    });
  }, [ref]);

  const clear = useCallback(() => {
    ref.current?.clear();
    onSelect('');
    setIsValid(false);
  }, [ref, onSelect]);

  const returnProps = useMemo(() => ({
    ref,
    onSelect,
    onSearch,
    isValid,
  }), [ref.current, onSearch, onSelect, isValid]);

  return {
    clear,
    setValue,
    props: returnProps,
  };
}
