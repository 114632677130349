import React from 'react';
import PageContainer from 'client/components/PageContainer';
import { omit } from 'lodash';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useParams, useHistory } from 'react-router-dom';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import AuthSwitchButton from 'client/buttons/AuthSwitchButton';
import { Card, Tab, Nav } from 'react-bootstrap';
import TabNavLink from 'client/tab/TabHelpers';
import BlockSpinner from 'client/spinners/BlockSpinner';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import { IUser } from 'client/user/types';
import UserForm, { UpdateUserFormFields } from 'client/user/UserForm';
import * as formUtils from 'client/utils/form';
import { Helmet } from 'react-helmet';
import SaveButton from 'client/buttons/SaveButton';
import { maybeAxiosErrorToErrorMap } from 'client/utils/errors';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import { CardHeaderObject } from 'client/card/CardHelpers';
import UserEmailsTable from 'client/user/UserEmailsTable';
import UserLogTable from 'client/user/UserLogTable';
import PageHeader from 'client/components/PageHeader';
import RoleGuard from 'client/guards/RoleGuard';
import UserCompanyMonitoredTable from 'client/userCompanyMonitored/UserCompanyMonitoredTable';
import {isFiniteNumber} from 'client/utils/number';
import { StatusLabel } from 'client/customer/CustomerFormatters';

interface UserEditPageReadRelationCountResponse {
  comments: number;
  valuation_reports: number;
  industry_reports: number;
  emails: number;
  company_monitored: number;
}

interface UserEditPageParams {
  userId: string;
  tab?: string;
}

export default function UserEditPage () {
  const { userId, tab = 'form' } = useParams<UserEditPageParams>();

  const history = useHistory();

  const readUserQuery = useQuery<IUser, Error>({
    queryKey: ['UserEditPage', userId],
    queryFn: () => axios.get(`/api/users/${userId}`).then(r => r.data),
  });

  const readRelationCountsQuery = useQuery<UserEditPageReadRelationCountResponse, Error>({
    queryKey: [`/api/users/${userId}/relationCounts`],
  });

  const updateUserMutation = useMutation<IUser, Error, Partial<IUser>>({
    mutationFn: vars => axios.patch(`/api/users/${userId}`, vars).then(r => r.data),
    onSuccess: () => {
      requestCallbacks.onSuccess('Användaren har uppdaterats');
      readUserQuery.refetch();
    },
  });

  const deleteUserMutation = useMutation<void, Error>({
    mutationFn: () => axios.delete(`/api/users/${userId}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Användaren har raderats');
      history.push('/users');
    },
  });

  const onSubmit = (form: UpdateUserFormFields, helpers: FormikHelpers<UpdateUserFormFields>) => {
    const changedUser = formValuesToUser(form);
    const update = formUtils.changes({...user, password: ''} as Record<string, any>, changedUser);
    updateUserMutation.mutateAsync(update)
      .then(updatedUser => {
        const newValues = userToFormValues(updatedUser);
        helpers.resetForm({values: newValues});
      }).catch(err => {
        helpers.setSubmitting(false);
        const errorMap = maybeAxiosErrorToErrorMap(err);
        if (errorMap) {
          helpers.setErrors(errorMap);
          return;
        }
        throw err;
      });
  };

  const counts = readRelationCountsQuery.data;
  const user = readUserQuery.data;

  return (
    <PageContainer fluid="sm">
      <Helmet>
        <title>Redigera användare "{userId}"</title>
      </Helmet>
      <PageHeader breadcrumbs={[{title: 'Användare', url: '/users'}, {title: userId}]}>
        Redigera användare
      </PageHeader>
      <Card>
        <CardHeaderObject
          objectId={userId}
          status={<>
            {user && (
              <StatusLabel value={user.inactivated_at} />
            )}
          </>}
        />
        <BlockSpinner isLoading={readUserQuery.isLoading} className="m-3" />
        <ErrorAlert error={readUserQuery.error} className="m-3" />
        <ErrorAlert error={readRelationCountsQuery.error} className="m-3" />
        {readUserQuery.isSuccess && user && (
          <>
            <Tab.Container
              defaultActiveKey="form"
              activeKey={tab}
              transition={false}
              mountOnEnter
              unmountOnExit
            >
              <Nav className="nav-tabs pt-3 px-3">
                <TabNavLink
                  eventKey="form"
                  title="Formulär"
                />
                <TabNavLink
                  eventKey="emails"
                  title="E-post"
                  count={counts?.emails}
                />
                <TabNavLink
                  eventKey="monitored"
                  title="Bevakade företag"
                  count={counts?.company_monitored}
                />
                <TabNavLink
                  eventKey="log"
                  title="Logg"
                />
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="form" className="p-4">
                  <Formik initialValues={userToFormValues(user)} onSubmit={onSubmit}>
                    {formikBag => (
                      <FormikForm>
                        <Card>
                          <Card.Body className="pb-1">
                            <UserForm formikBag={formikBag} />
                            <ErrorAlert error={updateUserMutation.error} />
                          </Card.Body>
                          <Card.Footer className="d-flex justify-content-end gap-2 p-2">
                            <SaveButton
                              type="submit"
                              isLoading={updateUserMutation.isPending}
                              disabled={!formikBag.isValid || formikBag.isSubmitting || !Object.keys(formikBag.touched).length}
                            />
                          </Card.Footer>
                        </Card>
                      </FormikForm>
                    )}
                  </Formik>
                </Tab.Pane>
                <Tab.Pane eventKey="emails" className="p-4">
                  <UserEmailsTable userId={userId} />
                </Tab.Pane>
                <Tab.Pane eventKey="monitored" className="p-4">
                  <UserCompanyMonitoredTable userId={userId} />
                </Tab.Pane>
                <Tab.Pane eventKey="log" className="p-4">
                  <UserLogTable userId={userId} />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <Card.Footer className="p-3">
              <ErrorAlert error={deleteUserMutation.error} />
              <div className="d-flex gap-2 justify-content-center justify-content-sm-start flex-wrap">
                <InspectObjectModalButton object={user} size="lg" className="px-2" />
                <RoleGuard role="admin">
                  <ConfirmDeleteButton
                    onConfirmedDelete={deleteUserMutation.mutateAsync}
                    confirmMessage="Är du säker på att du vill radera användaren permanent?"
                    buttonLabel="Radera användaren"
                  />
                </RoleGuard>
                {user.inactivated_at === null && user.role !== 'admin' ? (
                  <AuthSwitchButton
                    objectType="user"
                    objectId={user.id}
                    variant="outline-primary"
                  />
                ) : ''}
              </div>
            </Card.Footer>
          </>
        )}
      </Card>
    </PageContainer>
  );
}

function userToFormValues (user?: IUser): UpdateUserFormFields {
  const {
    name = '',
    email = '',
    role = 'noone',
    ip_addresses_allowed = [],
    inactivated_at = null,
    company_monitored_limit = null,
  } = user || {};
  return {
    name,
    email,
    role,
    ip_addresses_allowed: Array.isArray(ip_addresses_allowed) ? ip_addresses_allowed.join(',') : '',
    change_password: false,
    company_monitored_limit: company_monitored_limit || '',
    password: '',
    active: !Boolean(inactivated_at),
  };
}

function formValuesToUser (form: UpdateUserFormFields): Partial<IUser> {
  const { ip_addresses_allowed } = form;
  const user = omit(form, 'ip_addresses_allowed', 'change_password', 'password') as Partial<IUser>;
  if (form.change_password) user.password = form.password;
  if (typeof form.active === 'string') user.inactivated_at = form.active === 'true' ? null : new Date();
  user.ip_addresses_allowed = ip_addresses_allowed ? ip_addresses_allowed.split(',').map(s => s.trim()) : [];
  if (!isFiniteNumber(user.company_monitored_limit)) user.company_monitored_limit = null;
  return user;
}
