import { useMemo, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import ErrorAlert from 'client/components/ErrorAlert';
import { Helmet } from 'react-helmet';
import { IUser, UserRole } from 'client/user/types';
import PageHeader from 'client/components/PageHeader';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import TableFilterVirtual from 'client/table/TableFilterVirtual';
import { ColumnDefinitionFactory, FilterDefinitionFactory } from 'client/table/types';
import DateFormat from 'client/components/DateFormat';
import * as UserFormatters from 'client/user/UserFormatters';
import useAuth from 'client/hooks/useAuth';
import AuthSwitchButton from 'client/buttons/AuthSwitchButton';
import { TableProvider } from 'client/contexts/TableContext';

export interface UserTableFilterParams {
  role?: UserRole;
  search_term?: string;
  include_inactive?: boolean;
}

interface ListData {
  rows: IUser[];
  total_rows: number;
}

const defaultRows: ListData['rows'] = [];
const defaultFilterParams = {};
const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.ASC,
  ...defaultFilterParams,
};

export default function UserTablePage () {
  const {
    setFilterParams,
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
    params,
  } = useTableState({
    defaultState,
  });

  const listQuery = useQuery<ListData, Error>({
    queryKey: ['/api/users/list', params],
    placeholderData: keepPreviousData,
  });
  const rows = listQuery.data?.rows ?? defaultRows;

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(listQuery.data?.total_rows ?? 0);
  }, [listQuery.data?.total_rows]);

  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);

  return (
    <PageContainer fluid>
      <TableProvider tableSort={tableSort}>
        <Helmet>
          <title>Användare</title>
        </Helmet>
        <PageHeader>
          <span>
            Användare
          </span>
          <Link className="btn btn-outline-primary" to="/user/new">
            Ny användare
          </Link>
        </PageHeader>
        <ErrorAlert className="my-3" error={listQuery.error} />
        <Card>
          <Card.Header className="p-3">
            <TableFilterVirtual
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              filterDefinitions={filterDefinitions}
              filterParams={filterParams}
              setFilterParams={setFilterParams}

              columnDefinitions={columnDefinitions}

              onReload={listQuery.refetch}
              onReset={filterReset}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultTableColumns = [
  'id',
  'name',
  'email',
  'role',
  'created_at',
  'last_login_at',
  'actions',
];

const columnSortable = [
  'id',
  'name',
  'email',
  'role',
  'created_at',
  'last_login_at',
];


const roles: UserRole[] = ['noone', 'account_manager', 'coordinator', 'team_leader', 'admin'];
const roleOptionList = roles.map(role => ({
  value: role,
  label: <UserFormatters.UserRole value={role} />,
}));

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.input(['search_term', 'Sökterm']),
  filterDefs.select(['role', 'Roll'], roleOptionList),
  filterDefs.select(['include_inactive', 'Inaktiva'], [{value: '1', label: 'Inkludera inaktiva'}], {
    extraProps: {placeholder: 'Exkludera inaktiva'},
  }),
];

const columnDefinitionFactory: ColumnDefinitionFactory<IUser> = () => [
  columnDefs.cell(['id', 'ID'], props => {
    const auth = useAuth();
    return (
      <UserFormatters.UserAnchor
        value={props.row.id}
        inactivated={Boolean(props.row.inactivated_at)}
        disabled={props.row.role === 'admin' && !auth.isUserRole('admin')}
      />
    );
  }),
  columnDefs.simple(['name', 'Namn']),
  columnDefs.cell(['email', 'E-postadress'], props => (
    <a href={`mailto:${props.row.email}`}>{props.row.email}</a>
  )),
  columnDefs.cell(['role', 'Roll'], props => (
    <UserFormatters.UserRole value={props.row.role} />
  )),
  columnDefs.cell(['created_at', 'Skapad'], props => (
    <DateFormat value={props.row.created_at} format="YYYY-MM-DD" />
  )),
  columnDefs.cell(['last_login_at', 'Senaste inloggning'], props => (
    <DateFormat value={props.row.last_login_at} />
  )),
  columnDefs.actions(props => (
    <>
      {props.row.role !== 'admin' && props.row.inactivated_at === null && (
        <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
          <AuthSwitchButton
            className="py-0"
            objectType="user"
            objectId={props.row.id}
            size="sm"
            variant="outline-primary"
          />
        </div>
      )}
    </>
  )),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
