import { CustomerRow } from 'client/customer/types';
import { IUser } from 'client/user/types';
import {
  ICsAccountsExtendedIndustryReport,
  ICsBasic,
  ICsGroupAccountsExtendedIndustryReport,
} from 'client/cs/types';
import { IIndustryReport } from 'client/industryReport/types';
import { IValuationReport } from 'client/valuationReport/types';
import { IAccountsTableSettings, TNumberOfEmployeesIntervals } from 'client/accounts/types';
import { CreditReportRow } from 'client/creditReport/types';

export enum ReportQueueStatusEnum {
  NEW = 'Väntar på datum',
  WAITING = 'Väntar på bokslut',
  READY = 'Klar',
  ERROR = 'Fel',
}

export type ReportQueueStatus = keyof typeof ReportQueueStatusEnum;

export enum ReportQueueLanguageEnum {
  sv = 'Svenska',
  en = 'Engelska',
}

export type ReportQueueLanguage = keyof typeof ReportQueueLanguageEnum;

export enum ReportQueueReportTypeEnum {
  valuation_report = 'Företagsvärdering',
  industry_report = 'Branschrapport',
}

export type ReportQueueReportType = keyof typeof ReportQueueReportTypeEnum;

export interface IReportQueueReportSettings {
  accounts_from: string;
  accounts_to: string;
  company_use_group_accounts: boolean;
  [key: string]: any;
}

export interface IReportQueueFormOptions {
  accounts_table: IAccountsTableSettings;
}

export interface IReportQueue<TReportSettings = IReportQueueReportSettings> {
  id: number;
  customer_id: string | null;
  created_by_user_id: number | null;
  report_org_number: string;
  report_type: ReportQueueReportType;
  report_settings: TReportSettings;
  form_options: IReportQueueFormOptions;
  status: ReportQueueStatus;
  status_date: Date | string;
  production_date: Date | string;
  error_message: string | null;
  is_done: boolean;
  is_downloaded: boolean;
  is_emailed: boolean;
  comment: string;
  order_number: string | null;
  deliver_email: boolean;
  deliver_printed: boolean;
  language: ReportQueueLanguage;
  deleted_at: string | Date | null;
  created_at: string | Date | null;
  updated_at: string | Date | null;
}

export interface ICreateReportQueueData {
  report_queue: IReportQueue;
}

export interface IReportQueueWithRelations<TReportSettings = IReportQueueReportSettings> extends IReportQueue<TReportSettings> {
  customer?: CustomerRow;
  creator?: IUser;
  cs_basic?: ICsBasic;
  industry_report?: IIndustryReport | null;
  valuation_report?: IValuationReport | null;
  credit_report?: Pick<CreditReportRow, 'id' | 'org_number' | 'created_at' | 'kf_debt' | 'sum_anm'>;
}

export type ReportQueueLogType = 'created' |  'deleted' | 'restored' | 'updated' | 'generated';

export interface IReportQueueLog {
  id: string;
  date: Date | string;
  type: ReportQueueLogType;
  report_queue_id: number;
  by_user_id: string | null;
  data: Map<string, any>;
}

export interface IReportQueueLogWithRelations extends IReportQueueLog {
  by_user: null | IUser;
}

export interface IValuationAndIndustryReportSettings {
  // common to both industry and valuation report
  accounts_date_locked: boolean;
  accounts_from: string;
  accounts_to: string;
  company_accounts: ICsAccountsExtendedIndustryReport[] | ICsGroupAccountsExtendedIndustryReport[] | null;
  company_accounts_override: boolean;
  company_use_group_accounts: boolean;
  is_demo: boolean;

  // industry report specific
  // @see IIndustryReportSettings
  branch_id: string | null;
  company_exclude: boolean;
  include_companies: string[];
  sni_codes: string[];
  minimum_number_of_companies_required?: number;

  // valuation report specific
  // @see IValuationReportSettings
  nbr_employees_interval: TNumberOfEmployeesIntervals;
  number_of_accounts: number;
  overridden_value_factor: number | null;
  sni_code: string;
}

export interface IReportQueueCreateValuationAndIndustryReportFormData {
  report_org_number: string;
  report_settings: IValuationAndIndustryReportSettings;
  form_options: IReportQueueFormOptions;
  order_number: string | null;
  comment: string;
  customer_id: string | null;
  production_date: string | Date;
  language: ReportQueueLanguage;
  deliver_email: boolean;
  deliver_printed: boolean;
}
