import React from 'react';
import ErrorAlert from 'client/components/ErrorAlert';
import { Helmet } from 'react-helmet';
import { ErrorLikeType } from 'client/utils/errors';

interface AppErrorProps {
  error: ErrorLikeType;
}

export default function AppError (props: AppErrorProps) {
  const { error } = props;
  return (
    <div className="container mt-4">
      <Helmet>
        <title>Ett fel inträffade</title>
      </Helmet>
      <div className="d-flex justify-content-center">
        <ErrorAlert
          title="Ett fel inträffade och sidan kan inte visas"
          error={error}
        />
      </div>
    </div>
  );
}

