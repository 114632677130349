import React from 'react';
import { find, unzipWith, concat } from 'lodash';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export interface CompaniesSearchDTO {
  org_number: string;
  company_name: string;
  customer_id: string | null;
}

interface UseCompanySearchOptions {
  search: string | null;
  url?: string;
}

export const useCompanySearch = (options: UseCompanySearchOptions) => {
  const { search, url = '/api/company/search' } = options;
  return useQuery<CompaniesSearchDTO[]>({
    queryKey: [url, {term: search}],
    gcTime: Infinity,
    initialData: [],
    enabled: typeof search === 'string' && search.length > 0,
  });
};

interface OrgNumberToCompanyNameProps {
  value: string;
  children: (props: any) => any;
}
export const OrgNumberToCompanyName: React.FC<OrgNumberToCompanyNameProps> = React.memo(function OrgNumberToCompanyName (props: OrgNumberToCompanyNameProps) {
  const { value, children } = props;
  const queryClient = useQueryClient();
  const cachedData = queryClient.getQueriesData({ predicate: (query) => {
    return query.queryKey.includes('/api/company/search');
  }});
  const companyNameFromCache = (find(unzipWith(cachedData, concat)[1], { org_number: value }) as CompaniesSearchDTO)?.company_name;
  const { data, isFetching, isFetched } = useCompanySearch({ search: companyNameFromCache ? null : value });
  const name = companyNameFromCache || (isFetched && data && data[0]?.company_name);

  return children({
    value: name || value,
    isLoading: isFetching,
  });
});
