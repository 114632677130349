import React, { createContext, useContext, PropsWithChildren, FC } from 'react';
import { UseTableSorting } from 'client/hooks/useTableSorting';
import { UseTableSelectRows } from 'client/hooks/useTableSelectRows';

export type TableContextValue<T extends Record<string, unknown> | unknown = unknown> = {
  tableSort?: UseTableSorting;
  tableSelectRows?: UseTableSelectRows;
} & {
  [key in keyof T]: T[key];
}

type TableProviderProps = PropsWithChildren<TableContextValue> & {[key: string]: unknown};

const TableContext = createContext<TableContextValue | undefined>(undefined);

const TableProvider: FC<TableProviderProps> = React.memo(function TableProvider ({ children, ...rest }) {
  return (
    <TableContext.Provider value={rest}>
      {children}
    </TableContext.Provider>
  );
});

function useTableContext<T extends Record<string, unknown> | unknown = unknown> (): TableContextValue<T> {
  const context = useContext(TableContext) as TableContextValue<T>;
  if (!context) {
    throw new Error('useTableContext must be used within a TableProvider');
  }
  return context;
}

function useMaybeTableContext (): TableContextValue | undefined {
  return useContext(TableContext);
}

export { TableContext, TableProvider, useTableContext, useMaybeTableContext };
