import React from 'react';
import { ButtonProps } from 'react-bootstrap';
import LoadingButton from 'client/buttons/LoadingButton';
import { CustomerCompanyMonitoredRow } from 'client/customerCompanyMonitored/types';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import useAuth from 'client/hooks/useAuth';
import axios from 'client/axios';
import {Eye, EyeOff} from 'react-feather';

interface CustomerCompanyMonitoredQuickButtonProps extends ButtonProps {
  orgNumber: string;
  customerId?: string;
}

export default function CustomerCompanyMonitoredQuickButton (props: CustomerCompanyMonitoredQuickButtonProps) {
  const { orgNumber, customerId, variant:outerVariant, ...restOfProps } = props;
  const auth = useAuth();

  const queryClient = useQueryClient();

  const url = auth.isCustomer ? `/api/as_customer/monitoring/${orgNumber}` : `/api/customer_company_monitored/${customerId}/${orgNumber}`;

  const query = useQuery<CustomerCompanyMonitoredRow | null, Error>({
    queryKey: [url],
  });

  const mutation = useMutation<any, Error>({
    mutationFn: async () => {
      if (isMonitored) {
        await axios.delete(url);
        queryClient.setQueryData([url], () => null);
        requestCallbacks.onSuccess('Bevakningen har tagits bort');
      } else {
        const result = await axios.put(url, {});
        queryClient.setQueryData([url], () => result.data);
        requestCallbacks.onSuccess('Bevakningen har lagts till');
      }
    },
    onError: err => {
      requestCallbacks.onError(err);
    },
  });

  const onClick: React.MouseEventHandler = () => {
    mutation.mutateAsync();
  };

  const isMonitored: boolean | null = query.isSuccess ? Boolean(query.data) : null;
  if (isMonitored === null) return null;

  const variant = isMonitored ? outerVariant : `outline-${outerVariant}`;

  return (
    <LoadingButton
      {...restOfProps}
      variant={variant}
      onClick={onClick}
      isLoading={mutation.isPending || query.isFetching}
      disabled={mutation.isPending || query.isFetching}
    >
      {isMonitored ? (
        <><EyeOff size={18} /> Ta bort bevakning</>
      ) : (
        <><Eye size={18} /> Lägg till bevakning</>
      )}
    </LoadingButton>
  );
}
