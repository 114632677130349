import React from 'react';
import PageContainer from 'client/components/PageContainer';
import { Helmet } from 'react-helmet';
import PageHeader from 'client/components/PageHeader';
import UserCompanyMonitoredSettings from 'client/userCompanyMonitored/UserCompanyMonitoredSettings';
import UserCompanyMonitoredTableCard from 'client/userCompanyMonitored/UserCompanyMonitoredTableCard';

export default function UserCompanyMonitoredPage () {
  return (
    <PageContainer fluid="sm">
      <Helmet>
        <title>Mina företagsbevakningar</title>
      </Helmet>
      <PageHeader>
        Mina företagsbevakningar
      </PageHeader>
      <UserCompanyMonitoredSettings className="mb-4" />
      <UserCompanyMonitoredTableCard />
    </PageContainer>
  );
}
