import React, { useMemo, useState, useId } from 'react';
import { TChangeValue } from 'client/utils/form';
import { numberOfEmployeesIntervals } from 'client/accounts/types';
import RoleGuard from 'client/guards/RoleGuard';
import * as ReportQueueFormatters from 'client/reportQueue/ReportQueueFormatters';
import IdProvider from 'client/components/IdProvider';
import HelperTooltip from 'client/components/HelperTooltip';
import { isFiniteNumber } from 'client/utils/number';
import {
  Button,
  Card,
  Collapse,
  Form,
  FormCheckProps,
  FormControlProps,
  FormSelectProps,
  InputGroup,
} from 'react-bootstrap';
import { IReportQueueWithRelations, ReportQueueLanguage } from 'client/reportQueue/types';
import { EnglishFlag, SwedishFlag } from 'client/components/Flags';
import { ICsBasic } from 'client/cs/types';
import { CustomerRow } from 'client/customer/types';
import useAuth from 'client/hooks/useAuth';
import classNames from 'classnames';
import ReportQueueOverviewTable from 'client/reportQueue/ReportQueueOverviewTable';
import { ExternalLink, Eye, EyeOff } from 'react-feather';

interface AccountDateInputProps extends FormControlProps {
  name: string;
  label: React.ReactNode;
  className?: string;
  onChangeValue: TChangeValue;
  disabled?: boolean;
}
export function AccountDateInput (props: AccountDateInputProps) {
  const { name, className, label, disabled, onChangeValue, ...restOfProps } = props;
  const id = useId();
  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(name, ev.target.value);
  };
  return (
    <Form.Group className={className}>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <Form.Control
        {...restOfProps}
        onChange={onChange}
        type="date"
        required
        id={id}
        isInvalid={!restOfProps.value}
        disabled={disabled}
      />
      <Form.Control.Feedback type="invalid">Måste anges</Form.Control.Feedback>
    </Form.Group>
  );
}

interface AccountDateInputGroupProps {
  form: {
    report_settings: {
      accounts_date_locked: boolean;
      accounts_from: string;
      accounts_to: string;
    };
  };
  onChangeValue: TChangeValue;
}
export function AccountDateInputGroup (props: AccountDateInputGroupProps) {
  const { form, onChangeValue } = props;
  return (
    <div>
      <div className="d-flex flex-wrap gap-3 mb-3">
        <AccountDateInput
          name="report_settings.accounts_from"
          label="Bokslutsdatum från"
          value={form.report_settings.accounts_from}
          onChangeValue={onChangeValue}
          disabled={form.report_settings.accounts_date_locked}
        />
        <AccountDateInput
          name="report_settings.accounts_to"
          value={form.report_settings.accounts_to}
          onChangeValue={onChangeValue}
          label={<>Bokslutsdatum till <HelperTooltip>Årtalet här avgör vilket jämförelseår rapporten skapas på.</HelperTooltip></>}
          disabled={form.report_settings.accounts_date_locked}
        />
      </div>
      <BooleanFormCheck
        label={<>Lås bokslutsdatum <HelperTooltip>När denna kryssruta är markerad så ändras bokslutsdatumen ej av andra formulärkontroller</HelperTooltip></>}
        name="report_settings.accounts_date_locked"
        checked={form.report_settings.accounts_date_locked}
        onChangeValue={onChangeValue}
      />
    </div>
  );
}

interface IBooleanFormCheck extends FormCheckProps {
  name: string;
  onChangeValue: TChangeValue;
}
export function BooleanFormCheck (props: IBooleanFormCheck) {
  const { name, onChangeValue, ...restOfProps } = props;

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(name, ev.target.checked);
  };
  const id = useId();

  return (
    <Form.Check
      {...restOfProps}
      onChange={onChange}
      name={name}
      id={id}
      type="checkbox"
    />
  );
}

interface NumberOfEmployeesSelectProps extends FormSelectProps {
  name: string;
  onChangeValue: TChangeValue;
}
export const NumberOfEmployeesSelect: React.FC<NumberOfEmployeesSelectProps> = React.memo(function NumberOfEmployeesSelect (props: NumberOfEmployeesSelectProps) {
  const { name, value, onChangeValue, ...restOfProps } = props;

  const onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    onChangeValue(name, ev.target.value);
  };

  return (
    <Form.Select
      {...restOfProps}
      onChange={onChange}
      value={value ?? ''}
      required
    >
      {value === null && <option value="">Välj ett värde</option>}
      {numberOfEmployeesIntervals.map(interval => (
        <option value={interval} key={interval}>{interval}</option>
      ))}
    </Form.Select>
  );
});

interface INumberSuffixInput extends FormControlProps {
  label: React.ReactNode;
  suffix: string;
  name: string;
  required?: boolean;
  min?: number;
  max?: number;
  onChangeValue: TChangeValue;
}

export const NumberSuffixInput: React.FC<INumberSuffixInput> = React.memo(function NumberSuffixInput (props: INumberSuffixInput) {
  const {
    name,
    value,
    suffix,
    onChangeValue,
    label,
    required,
    min,
    max,
    ...restOfProps
  } = props;

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(name, ev.target.valueAsNumber);
  };

  const isInvalid = useMemo(() => {
    if (!isFiniteNumber(value)) return required;
    const num = value as number;
    if (isFiniteNumber(min) && num < (min as number)) return true;
    if (isFiniteNumber(max) && num > (max as number)) return true;
    return /\D/.test(String(num));
  }, [min, max, required, value]);

  const id = useId();
  return (
    <Form.Group>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <InputGroup hasValidation>
        <Form.Control
          {...restOfProps}
          name={name}
          value={value}
          min={min}
          max={max}
          onChange={onChange}
          required={required}
          type="number"
          isInvalid={isInvalid}
          id={id}
        />
        <InputGroup.Text>{suffix}</InputGroup.Text>
        <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  );
});

interface ILanguageChooser extends FormCheckProps {
  name: string;
  value: ReportQueueLanguage;
  onChangeValue: TChangeValue;
}
export function LanguageChooser (props: ILanguageChooser) {
  const { name, value, onChangeValue } = props;

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(name, ev.target.value);
  };

  return (
    <div className="d-flex gap-3 flex-wrap">
      
      <Form.Label className="mb-0">
        Rapportens språk{' '}
        <HelperTooltip>
          Anger rapportens språk. Värdet har hämtats från fältet "Föredraget språk" på kundkortet. Om du ändrar språk här så uppdateras INTE fältet på kundkortet.
        </HelperTooltip>
      </Form.Label>
      <IdProvider>
        {id => (
          <Form.Check
            type="radio"
            label={<><SwedishFlag /> Svenska</>}
            onChange={onChange}
            value="sv"
            id={id}
            checked={value === 'sv'}
          />
        )}
      </IdProvider>
      <IdProvider>
        {id => (
          <Form.Check
            type="radio"
            label={<><EnglishFlag /> Engelska</>}
            onChange={onChange}
            value="en"
            id={id}
            checked={value === 'en'}
          />
        )}
      </IdProvider>
    </div>
  );
}

interface DeliveryPreferencesProps {
  className?: string;
  onChangeValue: TChangeValue;
  customerData: Partial<CustomerRow> | null;
  form: {
    deliver_email: boolean;
    deliver_printed: boolean;
  };
}

export function DeliveryPreferences (props: DeliveryPreferencesProps) {
  const {
    form,
    onChangeValue,
    className,
    customerData,
  } = props;

  const auth = useAuth();

  const onChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeValue(ev.target.name, ev.target.checked);
  };

  const disabled = !customerData;
  const required = !auth.isUserRole(['admin', 'coordinator']) && !disabled && !form.deliver_email && !form.deliver_printed;

  return (
    <div className={className}>
      <Form.Label>
        Leveransinställningar till kund{' '}
        <HelperTooltip>
          Anger hur kunden vill ha rapporten levererad. Om rapporten ska sparas på en kund så måste du ange minst ett val. Finns ingen kund kan du inte ange några val.
        </HelperTooltip>
      </Form.Label>
      <IdProvider>
        {id => (
          <Form.Group>
            <Form.Check
              name="deliver_email"
              label={<>Digitalt <HelperTooltip>Rapporten och fakturan levereras till kundens e-post</HelperTooltip></>}
              onChange={onChange}
              id={id}
              checked={disabled ? false : form.deliver_email}
              disabled={disabled}
              required={required}
              isInvalid={required}
              value={form.deliver_email ? 'on' : ''}
            />
            <Form.Control.Feedback type="invalid">
              Ange ett värde
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </IdProvider>
      <IdProvider>
        {id => (
          <Form.Group>
            <Form.Check
              name="deliver_printed"
              label={<>Tryckt <HelperTooltip>Rapporten och fakturan levereras fysiskt till kundens adress</HelperTooltip></>}
              onChange={onChange}
              id={id}
              checked={disabled ? false : form.deliver_printed}
              disabled={disabled}
              required={required}
              isInvalid={required}
              value={form.deliver_printed ? 'on' : ''}
            />
            <Form.Control.Feedback type="invalid">
              Ange ett värde
            </Form.Control.Feedback>
          </Form.Group>
        )}
      </IdProvider>
      {required && !disabled && (
        <p className="text-danger mb-0 small">
          Du måste göra minst ett val
        </p>
      )}
    </div>
  );
}

interface IRecallPreviousReportQueueFormDataNotice {
  currentCustomerId: string | null;
  previousReportQueue: IReportQueueWithRelations;
  onClickRecallCustomerId: () => void;
  onClickRecallFormData: () => void;
  title?: string;
}
export function RecallPreviousReportQueueFormDataNotice (props: IRecallPreviousReportQueueFormDataNotice) {
  const {
    title = 'rapport',
    currentCustomerId,
    previousReportQueue,
    onClickRecallCustomerId,
    onClickRecallFormData,
  } = props;

  const isHandled = previousReportQueue.status === 'READY';
  const color = !isHandled ? 'danger' : 'secondary';
  const text = !isHandled ? 'white' : 'white';
  const headerClassName = classNames(color ? `bg-${color}` : null, text ? `text-${text}` : null);

  const [show, setShow] = useState<boolean>(!isHandled);

  return (
    <Card className="mt-3" border={color}>
      <Card.Header className={headerClassName}>
        <div className="d-flex justify-content-between align-items-center flex-wrap gap-2">
          {!isHandled ? (
            <strong>
              OBS! En tidigare {title} har skapats på samma företag.
              Den befintliga rapporten är fortfarande köad.
            </strong>
          ) : (
            <span style={{fontWeight: 500}}>
              En tidigare {title} har skapats på samma företag.
            </span>
          )}
          <Button size="sm" variant="outline-light" onClick={() => setShow(!show)}>
            {show ? <Eye size={16} /> : <EyeOff size={16} />}
          </Button>
        </div>
      </Card.Header>
      <Collapse in={show}>
        <div className="table-responsive">
          <ReportQueueOverviewTable reportQueue={previousReportQueue} className="mb-0" />
        </div>
      </Collapse>
      <Card.Footer className="border-top-0 p-3 d-flex gap-2 flex-wrap">
        {previousReportQueue.customer && (
          <Button
            variant="outline-primary"
            size="sm"
            onClick={onClickRecallCustomerId}
            disabled={previousReportQueue.customer_id === currentCustomerId}
          >
            Infoga samma kund
          </Button>
        )}
        {' '}
        <Button variant="outline-primary" size="sm" onClick={onClickRecallFormData}>
          Infoga samma data i formuläret
        </Button>
        <div>
          <ReportQueueFormatters.ReportQueueLink value={previousReportQueue} className="btn btn-outline-primary btn-sm d-inline-flex align-items-center gap-1">
            <ExternalLink size={12} />{' '}
            Öppna rapportkö #{previousReportQueue.id}
          </ReportQueueFormatters.ReportQueueLink>
        </div>
      </Card.Footer>
    </Card>
  );
}

interface IRecallPreviousFormCard {
  orgNumber: string;
  onClickRecall: () => void;
}

export function RecallPreviousFormCard (props: IRecallPreviousFormCard) {
  const { orgNumber, onClickRecall } = props;

  return (
    <Card>
      <Card.Body className="py-2 px-3 small d-flex align-items-center">
        <span>
          Det finns ett tidigare sparat formulär på organisationsnummer {orgNumber}.
        </span>
        <Button
          size="sm"
          variant="outline-primary"
          onClick={onClickRecall}
          className="ms-2"
        >
          Återuppta
        </Button>
      </Card.Body>
    </Card>
  );
}

interface ISwitchToGroupMotherCompanyAlert {
  currentOrgNumber: string;
  currentCompanyName: string;
  onSwapOrgNumber: (orgNumber: string) => void;
  groupMotherCompany: ICsBasic;
}
export function SwitchToGroupMotherCompanyAlert (props: ISwitchToGroupMotherCompanyAlert) {
  const {
    currentOrgNumber,
    currentCompanyName,
    groupMotherCompany,
    onSwapOrgNumber,
  } = props;

  const onClick = () => {
    onSwapOrgNumber(groupMotherCompany.org_number);
  };

  // the group mother has its own group structure which points to itself
  // if that happens we don't need to render at all
  if (currentOrgNumber === groupMotherCompany.org_number) {
    return null;
  }

  return (
    <Card className="my-4">
      <Card.Body className="p-3 small d-flex align-items-baseline gap-2 flex-wrap">
        {currentCompanyName} har en koncernmoder: {groupMotherCompany.company_name} ({groupMotherCompany.org_number}).
        <Button onClick={onClick} size="sm" variant="outline-primary">
          Byt företag till koncernmodern
        </Button>
      </Card.Body>
    </Card>
  );
}

interface CompanyAccountsOverrideCheckboxProps {
  form: {report_settings: {company_accounts_override: boolean}};
  onChangeAccountsOverrideValue: (name: string, checked: boolean) => void;
}

export function CompanyAccountsOverrideCheckbox (props: CompanyAccountsOverrideCheckboxProps) {
  const { form, onChangeAccountsOverrideValue } = props;
  return (
    <RoleGuard role={null}>
      <BooleanFormCheck
        className="mb-0"
        label={<>Visa företagets nyckeltal <HelperTooltip>Visar redigeringsformuläret för företagets nyckeltal</HelperTooltip></>}
        name="report_settings.company_accounts_override"
        checked={form.report_settings.company_accounts_override}
        onChangeValue={onChangeAccountsOverrideValue}
      />
    </RoleGuard>
  );
}

interface IsDemoCheckboxProps {
  form: {report_settings: {is_demo: boolean}};
  onChangeValue: (name: string, value: any) => void;
}

export function IsDemoCheckbox (props: IsDemoCheckboxProps) {
  const { form, onChangeValue } = props;
  return (
    <BooleanFormCheck
      label={<>Skapa demorapport <HelperTooltip>På en demorapport är företagsnamnet utbytt mot "Demobolaget"</HelperTooltip></>}
      name="report_settings.is_demo"
      checked={form.report_settings.is_demo}
      onChangeValue={onChangeValue}
    />
  );
}

interface CompanyExcludeCheckboxProps {
  form: {report_settings: {company_exclude: boolean}};
  onChangeValue: (name: string, value: any) => void;
}

export function CompanyExcludeCheckbox (props: CompanyExcludeCheckboxProps) {
  const { form, onChangeValue } = props;
  return (
    <BooleanFormCheck
      label={<>Exkludera företagets data från branschrapporten <HelperTooltip>Endast jämförelsebolagen finns med i branschrapporten</HelperTooltip></>}
      name="report_settings.company_exclude"
      checked={form.report_settings.company_exclude}
      onChangeValue={onChangeValue}
    />
  );
}
