import { useMemo, useCallback, useState, useEffect, useRef } from 'react';
import { isEmpty, omit, mapValues, pickBy, isNull } from 'lodash';
import { useDebounce } from 'use-debounce';
import { produceSetMap } from 'client/utils/react';
import useHistoryQuerystring from 'client/hooks/useHistoryQuerystring';
import useTableSorting, { OrderDirection, UseTableSorting } from 'client/hooks/useTableSorting';
import useTablePagination, { UseTablePaginationProps, UseTablePagination } from 'client/hooks/useTablePagination';
import isEqual from 'react-fast-compare';

export { OrderDirection } from 'client/hooks/useTableSorting';

export type FilterFieldValue = undefined | string | null;

interface TableStateBase extends Record<string, FilterFieldValue> {
  currentPage?: string;
  perPage?: string;
  orderBy?: string;
  orderDirection?: OrderDirection;
}

export interface UseTableStateProps extends Pick<UseTablePaginationProps, 'rowsPerPageMax'> {
  defaultTableColumns?: string[];
  defaultState: TableStateBase;
}

interface UseTableState {
  filterParams: ReturnType<typeof pickFilterParams>;
  filterReset: () => void;
  setFilterParams: React.Dispatch<React.SetStateAction<TableStateBase>>;
  setStateMap: ReturnType<typeof produceSetMap>;
  tablePagination: UseTablePagination;
  tableSort: UseTableSorting;
  tableColumns: string[];
  setTableColumns: React.Dispatch<React.SetStateAction<string[]>>;
  params: UseTablePagination['params'] & UseTableSorting['params'];
}

const useTableState = (props: UseTableStateProps): UseTableState => {
  const { defaultState, defaultTableColumns = [], rowsPerPageMax } = props;
  const { parsedSearch, setSearch } = useHistoryQuerystring<TableStateBase>();

  const isInited = useRef(false);

  const [state, setState] = useState<TableStateBase>(() => ({
    ...pickFilterParams({
      ...defaultState,
      ...parsedSearch,
    }),
  }));

  const [tableColumns, setTableColumns] = useState<string[]>(defaultTableColumns);

  const setStateMap = useCallback((updates: Record<string, any>) => {
    setState(produceSetMap(updates));
  }, [setState]);

  const filterParams = useMemo(() => pickFilterParams(state), [state]);

  const notEmptyFilterParams = useMemo(() => {
    const notEmptyFilterParams = pickBy(filterParams, v => v !== '' && !isNull(v));
    return notEmptyFilterParams;
  }, [filterParams]);

  const tablePagination = useTablePagination({
    page: parsedSearch.currentPage ? parseInt(parsedSearch.currentPage, 10) || 1 : undefined,
    rowsPerPage: parsedSearch.perPage ? parseInt(parsedSearch.perPage, 10) || 20 : undefined,
    rowsPerPageMax,
  });

  const setFilterParams = useCallback<typeof setState>((value) => {
    setState(value);
    resetPagination();
  }, [setState, tablePagination.currentPage]);

  const resetPagination = () => {
    if (tablePagination.currentPage === 1 || !isInited.current) return;
    tablePagination.onChangePage(1);
  };

  const tableSort = useTableSorting({
    initialOrderBy: parsedSearch.orderBy || defaultState.orderBy,
    initialOrderDirection: parsedSearch.orderDirection as OrderDirection || defaultState.orderDirection,
    onChange: resetPagination,
  });

  const updateUrlParams = (replace: boolean = false) => {
    const mergedFilters = {
      ...pickFilterParams(defaultState),
      ...notEmptyFilterParams,
    };
    setSearch({
      orderBy: tableSort.currentOrderBy,
      orderDirection: tableSort.currentOrderDirection,
      ...mergedFilters,
      currentPage: String(tablePagination.currentPage),
      perPage: String(tablePagination.rowsPerPage),
    }, replace);
  };

  useEffect(() => {
    if (!isInited.current) {
      return;
    }

    updateUrlParams();
  }, [
    // state we want to sync with URL
    tablePagination.currentPage,
    tablePagination.rowsPerPage,
    tableSort.currentOrderBy,
    tableSort.currentOrderDirection,
    filterParams,
  ]);

  // populate url with search params on navigation to a table page route
  useEffect(() => {
    isInited.current = true;

    if (isEmpty(parsedSearch)) {
      updateUrlParams(true);
    }
  }, []);

  const filterReset = useCallback(() => {
    // hackish reset trigger for {@link client/components/CompanySearch.tsx}
    // FIXME: if `defaultState.org_number` is defined, then reset is broken
    setState((state) => {
      const feildsListWithResetByNull = ['org_number'];
      return {
        ...mapValues(pickFilterParams(state), (value, key) => feildsListWithResetByNull.includes(key) ? null : ''),
        ...pickFilterParams({
          ...defaultState,
        }),
      };
    });
    resetPagination();
  }, [setState, defaultState, tablePagination.currentPage]);

  // this returns an object that is compatible with the /list endpoints on the server
  // it can be passed straight into "params" when querying the server
  const [params] = useDebounce({
    ...notEmptyFilterParams,
    ...tableSort.params,
    ...tablePagination.params,
  }, 500, {equalityFn: isEqual});

  return {
    filterParams,
    filterReset,
    setFilterParams,
    setStateMap,
    tablePagination,
    tableSort,
    tableColumns,
    setTableColumns,
    params,
  };
};

export default useTableState;

// everything not related to order and page is considered filters in the table state
function pickFilterParams (state: TableStateBase) {
  return omit(state, ['currentPage', 'perPage', 'orderBy', 'orderDirection']);
}
