import { useState, useCallback, useRef, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import LoadingButton from 'client/buttons/LoadingButton';
import { useModalState } from 'client/hooks/useModalState';

interface ConfirmActionModalProps {
  confirmMessage?: React.ReactNode;
  declineMessage?: React.ReactNode;
  onConfirm: () => void | Promise<any>;
  onDecline: () => void;
  message: React.ReactNode;
  show: boolean;
  isLoading?: boolean;
  hideButtons?: boolean;
}

export function useConfirmModalState () {
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<CallableFunction | null>(null);

  const { show, onShow, onToggle, onHide } = useModalState();

  useEffect(() => {
    if (!show) ref.current = null;
  }, [show]);

  const confirmAction = useCallback((callback: () => any) => {
    onShow();
    ref.current = callback;
  }, [onShow]);

  return {
    onToggle,
    onShow,
    onHide,
    props: {
      show,
      isLoading,
      onDecline: onHide,
      onConfirm: () => {
        const result = ref.current?.();

        if (result instanceof Promise) {
          setIsLoading(true);
          result.finally(() => {
            setIsLoading(false);
            onHide();
          });
          return;
        }
        onHide();
      },
    },
    confirmAction,
  };
}

export function ConfirmActionModal (props: ConfirmActionModalProps) {
  const {
    isLoading,
    show,
    confirmMessage = 'Ja',
    declineMessage = 'Nej',
    onConfirm,
    onDecline,
    message,
    hideButtons,
  } = props;

  return (
    <Modal show={show} onHide={onDecline} centered>
      <Modal.Body className="text-center d-flex flex-column gap-3 p-4">
        {typeof message === 'string' ? (
          <p>
            <strong>
              {message}
            </strong>
          </p>
        ) : message}
        {hideButtons ? null : (
          <div className="d-flex justify-content-center gap-2">
            <LoadingButton
              className="px-4"
              variant="success"
              onClick={onConfirm}
              isLoading={isLoading}
            >
              {confirmMessage}
            </LoadingButton>{' '}
            <LoadingButton variant="danger" onClick={onDecline} className="px-4">
              {declineMessage}
            </LoadingButton>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
}
