import React from 'react';
import { BadgeProps } from 'react-bootstrap';
import { CreditReportRow, CreditReportStatus } from 'client/creditReport/types';
import {Info, ThumbsDown, ThumbsUp} from 'react-feather';
import StatusBadge from 'client/components/StatusBadge';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import NumberFormat from 'client/components/NumberFormat';
import { IdShort } from 'client/components/MiscFormatters';
import ObjectId from 'client/components/ObjectId';
import { CreditReportWithRelationsRow } from 'client/creditReport/types';
import CreditReportModal from 'client/creditReport/CreditReportModal';
import CreditReportLoadingModal from 'client/creditReport/CreditReportLoadingModal';
import CreditReportCompanyEventModal from 'client/creditReport/CreditReportCompanyEventModal';
import { CompanyMontoredUpdateChangeCode } from 'client/companyMonitoredUpdate/CompanyMonitoredUpdateFormatters';
import { UserAnchor } from 'client/user/UserFormatters';

interface CreditReportModalButtonProps {
  value: CreditReportWithRelationsRow;
}

export function CreditReportModalButton (props: CreditReportModalButtonProps) {
  const { value:creditReport } = props;
  return (
    <ModalOpeningButton
      Modal={CreditReportModal}
      modalProps={{creditReport}}
      variant="outline-primary"
      size="sm"
      className="py-0"
      buttonAs={ObjectId}
      as="a"
      href="#"
    >
      <IdShort value={creditReport.id} />
    </ModalOpeningButton>
  );
}

interface CreditReportCreatorProps {
  value: CreditReportWithRelationsRow;
}

export function CreditReportCreator (props: CreditReportCreatorProps) {
  const { value } = props;
  const {
    created_by_user_id,
    created_by_monitoring_update,
    company_monitored_update_change_code,
    creator,
  } = value;
  if (created_by_user_id) {
    return (
      <UserAnchor value={creator} />
    );
  }
  if (created_by_monitoring_update) {
    return (
      <span>Händelse: {company_monitored_update_change_code ? <CompanyMontoredUpdateChangeCode value={company_monitored_update_change_code} /> : '(Ingen)'}</span>
    );
  }
  return <span>-</span>;
}

interface CreditReportLoadingModalButtonProps {
  value: string;
}

export function CreditReportLoadingModalButton (props: CreditReportLoadingModalButtonProps) {
  const { value:creditReportId } = props;
  return (
    <ModalOpeningButton
      Modal={CreditReportLoadingModal}
      modalProps={{creditReportId}}
      variant="outline-primary"
      size="sm"
      className="py-0"
      buttonAs={ObjectId}
      as="a"
      href="#"
    >
      <IdShort value={creditReportId} />
    </ModalOpeningButton>
  );
}

interface CreditReportCompanyEventModalButtonProps {
  value: string;
}

export function CreditReportCompanyEventModalButton (props: CreditReportCompanyEventModalButtonProps) {
  const { value:creditReportId } = props;
  return (
    <ModalOpeningButton
      Modal={CreditReportCompanyEventModal}
      modalProps={{creditReportId}}
      size="sm"
      className="py-0"
      as="a"
      href="#"
      variant="outline-primary"
    >
      Händelser
    </ModalOpeningButton>
  );
}

interface StatusProps {
  value: CreditReportStatus;
}

export function Status (props: StatusProps) {
  const { value } = props;
  switch (value) {
    default: return value;
    case 'new': return <>Nyskapad</>;
    case 'error': return <>Fel</>;
    case 'missing': return <>Saknas</>;
    case 'result': return <>Svar</>;
  }
}

interface StatusLabelProps extends BadgeProps {
  value: CreditReportStatus;
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  switch (value) {
    default: return <StatusBadge {...restOfProps}>{String(value)}</StatusBadge>;
    case 'new': return <StatusBadge bg="secondary" {...restOfProps}><Status value={value} /></StatusBadge>;
    case 'missing': return <StatusBadge bg="warning" {...restOfProps}><Status value={value} /></StatusBadge>;
    case 'result': return <StatusBadge bg="success" {...restOfProps}><Status value={value} /></StatusBadge>;
    case 'error': return <StatusBadge bg="danger" {...restOfProps}><Status value={value} /></StatusBadge>;
  }
}

interface CommentaryThumbProps {
  value: '-1' | '0' | '1';
  iconSize?: number;
}

export function CommentaryThumb (props: CommentaryThumbProps) {
  const { value, iconSize = 16 } = props;
  switch (value) {
    default: return <></>;
    case '-1': return <ThumbsDown size={iconSize} className="text-danger" />;
    case '0': return <Info size={iconSize} />;
    case '1': return <ThumbsUp size={iconSize} className="text-success" />;
  }
}

interface SumAnsokanProps {
  value: Pick<CreditReportRow, 'sum_ansokan'>;
}

export function SumAnsokan (props: SumAnsokanProps) {
  const { value:row } = props;
  const value = row.sum_ansokan || 0;
  return (
    <small className={value > 0 ? 'text-danger' : ''}>
      <NumberFormat value={value} format="currency" />
    </small>
  );
}

interface SumAnmProps {
  value: Pick<CreditReportRow, 'sum_anm'>;
}

export function SumAnm (props: SumAnmProps) {
  const { value:row } = props;
  const value = row.sum_anm || 0;
  return (
    <small className={value > 0 ? 'text-danger' : ''}>
      <NumberFormat value={value} format="currency" />
    </small>
  );
}

interface KfDebtProps {
  value: Pick<CreditReportRow, 'kf_debt'>;
}

export function KfDebt (props: KfDebtProps) {
  const { value:row } = props;
  const value = row.kf_debt || 0;
  return (
    <small className={value > 0 ? 'text-danger' : ''}>
      <NumberFormat value={value} format="currency" />
    </small>
  );
}

interface DebtSumProps {
  value: Pick<CreditReportRow, 'kf_debt' | 'sum_anm' | 'sum_ansokan'>;
}

export function DebtSum (props: DebtSumProps) {
  const { value } = props;
  const kfDebt = value.kf_debt || 0;
  const sumAnm = value.sum_anm || 0;
  const sumAns = value.sum_ansokan || 0;
  const sum = kfDebt + sumAnm + sumAns;
  return (
    <small className={sum > 0 ? 'text-danger' : ''}>
      <NumberFormat value={sum} format="currency" />
    </small>
  );
}
