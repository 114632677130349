import React, { useState, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { keepPreviousData, useMutation, useQuery } from '@tanstack/react-query';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useParams } from 'react-router-dom';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import ReportQueueEditInnerPage from 'client/reportQueue/ReportQueueEditInnerPage';
import {
  IIndustryReportFormData,
  IIndustryReportsGetReportQueueInfoData,
} from 'client/industryReport/types';
import IndustryReportForm from 'client/industryReport/IndustryReportForm';
import BlockSpinner from 'client/spinners/BlockSpinner';
import { IReportQueue } from 'client/reportQueue/types';
import { StatusLabel } from 'client/reportQueue/ReportQueueFormatters';
import { CardHeaderObject } from 'client/card/CardHelpers';
import { Card } from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import PageHeader from 'client/components/PageHeader';

interface IIndustryReportUpdateVars {
  reportQueueId: string;
  form: IIndustryReportFormData;
}

interface IIndustryReportEditPageParams {
  reportQueueId: string;
  tab?: string;
}

interface IIndustryReportEditPage { }
const IndustryReportEditPage: React.FC<IIndustryReportEditPage> = React.memo(function IndustryReportEditPage () {
  const { reportQueueId } = useParams<IIndustryReportEditPageParams>();

  const [form, setForm] = useState<IIndustryReportFormData | null>(null);

  const getReportQueueInfoData = useQuery<IIndustryReportsGetReportQueueInfoData, Error>({
    queryKey: ['IndustryReportEditPage', reportQueueId],
    queryFn: () => axios.get(`/api/industry_reports/report_queue/${reportQueueId}`).then(r => r.data),
    placeholderData: keepPreviousData,
  });

  const { data } = getReportQueueInfoData;

  useEffect(() => {
    if (!data) return;
    setForm(data.reportQueue);
  }, [data]);

  const updateIndustryReportMutation = useMutation<IReportQueue, Error, IIndustryReportUpdateVars>({
    mutationKey: ['UpdateIndustryReport'],
    mutationFn: vars => axios.put(`/api/industry_reports/report_queue/${vars.reportQueueId}`, vars.form).then(result => result.data),
    onSuccess: () => {
      requestCallbacks.onSuccess('Branschrapporten har uppdaterats');
    },
  });

  const onSubmitUpdateForm = () => {
    const vars = {reportQueueId, form: form as IIndustryReportFormData};
    updateIndustryReportMutation.mutate(vars);
  };

  return (
    <PageContainer fluid="md">
      <Helmet>
        <title>Redigera köad branschrapport "{reportQueueId}"</title>
      </Helmet>
      <PageHeader breadcrumbs={[{title: 'Rapportkö', url: '/report_queue/new'}, {title: '#' + reportQueueId}]}>
        Redigera köad branschrapport
      </PageHeader>
      <Card>
        <CardHeaderObject
          objectId={reportQueueId}
          extra={form?.report_org_number}
          status={<>
            {data?.reportQueue && (
              <StatusLabel value={data.reportQueue.status} done={data.reportQueue.is_done} />
            )}
          </>}
        />
        <ErrorAlert error={getReportQueueInfoData.error} className="m-3" />
        <BlockSpinner isLoading={getReportQueueInfoData.isLoading} className="m-3" />
        {getReportQueueInfoData.isSuccess && data && form && (
          <ReportQueueEditInnerPage
            data={data}
            isRefetching={getReportQueueInfoData.isRefetching}
            Form={
              <IndustryReportForm
                isEditForm
                data={data}
                form={form as IIndustryReportFormData}
                setForm={setForm as unknown as React.Dispatch<React.SetStateAction<IIndustryReportFormData>>}
                onSubmitForm={onSubmitUpdateForm}
                submitError={updateIndustryReportMutation.error}
                submitIsLoading={updateIndustryReportMutation.isPending}
              />
            }
          />
        )}
      </Card>
    </PageContainer>
  );
});
export default IndustryReportEditPage;
