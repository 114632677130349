import React, {useMemo} from 'react';
import { useQuery } from '@tanstack/react-query';
import * as CompanyEventFormatters from 'client/companyEvent/CompanyEventFormatters';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import { CompanyEventRowWithRelations } from 'client/companyEvent/types';
import {ColumnDefinition} from 'client/table/types';
import CardSimpleTable from 'client/card/CardSimpleTable';
import {Search} from 'react-feather';
import {Link} from 'react-router-dom';

interface CompanyMonitoredEventTableProps {
  orgNumber: string;
  className?: string;
}

interface CompanyEventRowWithId extends CompanyEventRowWithRelations {
  id: string;
}

function CompanyMonitoredEventTable (props: CompanyMonitoredEventTableProps) {
  const { className, orgNumber } = props;

  const query = useQuery<CompanyEventRowWithRelations[], Error>({
    queryKey: [`/api/company_monitored/${orgNumber}/events`],
  });

  const rows: CompanyEventRowWithId[] = useMemo(() => {
    if (!query.data) return [];
    return query.data.map(row => ({
      id: [row.org_number, row.event_type, row.event_date, row.event_type_mod].filter(v => v).join('_'),
      ...row,
    }));
  }, [query.data]);

  return (
    <div>
      <div className="mb-3">
        <Link
          className="btn btn-outline-primary py-0 d-inline-flex gap-1 align-items-center px-1"
          to={`/company_event?org_number=${orgNumber}`}
        >
          <Search size={18} />
          Alla händelser
        </Link>
      </div>
      <CardSimpleTable
        className={className}
        query={query}
        title="20 senaste företagshändelser"
        columns={columns}
        rows={rows}
      />
    </div>
  );
}
export default React.memo(CompanyMonitoredEventTable);

const columns: ColumnDefinition<CompanyEventRowWithId>[] = [
  columnDefs.date(['event_date', 'Datum']),
  columnDefs.cell(['event_type', 'Typ'], props => (
    <CompanyEventFormatters.CompanyEventType value={props.row.fmt_event_type} />
  )),
  columnDefs.cell(['prev', 'Från'], props => (
    <CompanyEventFormatters.CompanyEventPrev value={props.row} />
  )),
  columnDefs.cell(['curr', 'Till'], props => (
    <CompanyEventFormatters.CompanyEventCurr value={props.row} />
  )),
  columnDefs.cell(['diff', 'Differens'], props => (
    <CompanyEventFormatters.CompanyEventDiff value={props.row} />
  )),
  columnDefs.actions(),
];
