import React from 'react';

interface IdProps {
  value: string;
}

export function Id (props: IdProps) {
  const { value } = props;
  return (
    <code className="border rounded p-1 cursor-help" title={value}>
      {value.slice(0, 5)}…
    </code>
  );
}

interface DocumentTypeProps {
  value: string;
}

export function DocumentType (props: DocumentTypeProps) {
  const { value } = props;
  switch (value) {
    default: return <>{value}</>;
    case 'AnnualReport': return <>Årsredovisning</>;
    case 'InterimReport': return <>Interimsredovisning</>;
    case 'GroupReport': return <>Koncernredovisning</>;
    case 'Other': return <>Övrigt</>;
  }
}
