import { CustomerLogType, CustomerLogRow } from 'client/customer/types';
import { LogUpdatedSummary } from 'client/components/MiscFormatters';
import { fieldName } from 'client/customer/CustomerFormatters';

interface CustomerLogDescriptionProps {
  value: CustomerLogRow;
}
export function CustomerLogDescription (props: CustomerLogDescriptionProps) {
  const { value:log } = props;
  return (
    <span>
      {logTypeToDescription(log.type)}
      {log.type === 'updated' && log.data?.changes && (
        <>
          {' '}
          <LogUpdatedSummary
            value={log.data}
            formatFieldName={fieldName}
          />
        </>
      )}
    </span>
  );
}

export function logTypeToDescription (type: CustomerLogType): string {
  switch (type) {
    default: return `Okänd loggtyp: ${String(type)}`;
    case 'created': return 'Kund skapad';
    case 'updated': return 'Kund uppdaterad';
    case 'report_downloaded': return 'Rapport nedladdad';
    case 'login_failed': return 'Misslyckad inloggning';
    case 'logout': return 'Utloggad';
    case 'login': return 'Inloggad';
  }
}
