import React, { useMemo, useState } from 'react';
import { Table, Form } from 'react-bootstrap';
import IdProvider from 'client/components/IdProvider';
import AccountsTableRow from 'client/accounts/AccountsTableRow';
import useFocusControl from 'client/hooks/useFocusControl';
import { allAccountRows } from 'client/accounts/utils';
import { IAccounts } from 'client/accounts/types';
import OverlaySpinner from 'client/spinners/OverlaySpinner';

interface AccounstTableFixedProps {
  accounts: null | IAccounts[];
  limit?: number;
  isLoading?: boolean;
}

interface AccountsTableFixedSettings {
  rowFilter: string;
  hideEmptyRows: boolean;
}

const AccountsTableFixed: React.FC<AccounstTableFixedProps> = React.memo(function AccountsTableFixed (props: AccounstTableFixedProps) {
  const { accounts:outerAccounts, limit = 5, isLoading = false } = props;

  const [settings, setSettings] = useState<AccountsTableFixedSettings>({
    rowFilter: '',
    hideEmptyRows: true,
  });

  const onChangeSetting = (key:(keyof AccountsTableFixedSettings), value: any) => {
    setSettings(prevSettings => ({...prevSettings, [key]: value}));
  };

  const rowFilterProps = useFocusControl<string, keyof AccountsTableFixedSettings>(settings.rowFilter, onChangeSetting);

  const accounts = useMemo<IAccounts[]>(() => {
    if (!outerAccounts) return [];
    return outerAccounts.slice(0, limit);
  }, [outerAccounts, limit]);

  const onChangeHideEmptyRows = (ev: React.ChangeEvent<HTMLInputElement>) => {
    onChangeSetting('hideEmptyRows', ev.target.checked);
  };

  return (
    <div>
      <div className="p-3 d-flex gap-3 align-items-center flex-wrap border-end border-start">
        <IdProvider>
          {id => (
            <Form.Group>
              <Form.Control
                {...rowFilterProps}
                id={id}
                name="rowFilter"
                placeholder="Inget radfilter"
              />
            </Form.Group>
          )}
        </IdProvider>
        <IdProvider>
          {id => (
            <Form.Check
              label="Dölj tomma rader"
              name="hideEmptyRows"
              onChange={onChangeHideEmptyRows}
              id={id}
              checked={settings.hideEmptyRows}
              type="checkbox"
            />
          )}
        </IdProvider>
      </div>
      <div className="table-responsive">
        <OverlaySpinner isLoading={isLoading} />
        <Table size="sm" className="align-middle mb-0" bordered>
          <colgroup>
            <col className="border-end" />
            <col span={accounts.length - 1} className="border-end" />
            <col />
          </colgroup>
          <tbody>
            {accounts.length > 0 ? (
              <>
                {allAccountRows.map((row, index) => (
                  <AccountsTableRow
                    key={['spacer', 'header'].includes(row.type) ? index : row.key}
                    row={row}
                    disabled
                    accounts={accounts}
                    hideEmptyRows={settings.hideEmptyRows}
                    rowFilter={settings.rowFilter}
                  />
                ))}
              </>
            ): (
              <tr>
                <td colSpan={2}>Företaget har inga bokslut här.</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
});
export default AccountsTableFixed;
