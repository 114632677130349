import React from 'react';
import CustomerSurveyRespondPage from 'client/customerSurvey/CustomerSurveyRespondPage';
import LoginPage from 'client/login/LoginPage';
import ResetPasswordPage from 'client/login/ResetPasswordPage';
import ForgotPasswordPage from 'client/login/ForgotPasswordPage';
import PublicRoute from 'client/router/PublicRoute';
import { Route } from 'react-router-dom';

const publicRoutes = [
  <Route key={0} exact path="/survey/:customerSurveyId" component={CustomerSurveyRespondPage} />,
  <PublicRoute key={1} exact path="/reset_password/:token" component={ResetPasswordPage} />,
  <PublicRoute key={2} exact path="/forgot_password" component={ForgotPasswordPage} />,
  <PublicRoute key={3} exact path="/login" component={LoginPage} />,
];

export default publicRoutes;
