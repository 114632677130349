import React, { useId, useMemo, useEffect, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { FilterDefinition } from 'client/table/types';
import { TableFilterInputGroup } from 'client/table/TableUtils';
import CompanySearch, { useCompanySearchManager } from 'client/components/CompanySearch';
import { UndefinedInitialDataOptions, useQuery } from '@tanstack/react-query';
import { IUser } from 'client/user/types';
import useFocusControl from 'client/hooks/useFocusControl';
import moment from 'moment';
import { isNull, defaultsDeep, flatten } from 'lodash';
import {CompanyEventTypesList} from 'client/companyEvent/types';
import {AxiosResponse} from 'axios';

type IdAndLabel = [string, string];

// a simple string input
export const input = (idAndLabel: IdAndLabel, extendWith = {}): FilterDefinition<string> => ({
  id: idAndLabel[0],
  label: idAndLabel[1],
  Render: props => {
    const { id, label, size, value:outerValue, validate, onChange:onChangeOuter, extraProps = {} } = props;

    const {
      onFocus,
      onChange,
      onBlur,
      value,
    } = useFocusControl(outerValue ?? '', onChangeOuter);

    const onReset = useCallback(() => {
      onChangeOuter(id, '');
    }, [id, onChangeOuter]);

    const onKeyDownInput = useCallback((ev: React.KeyboardEvent<HTMLInputElement>) => {
      switch (ev.key) {
        default: return;
        case 'Enter': {
          onChangeOuter(id, ev.currentTarget.value);
        } break;
      }
    }, [onChangeOuter]);

    const isInvalid: boolean = useMemo(() => {
      if (value === null || typeof value === 'undefined' || value === '') return false;
      return validate ? !validate(value) : false;
    }, [value, validate]);

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        resetDisabled={!Boolean(value)}
        onReset={onReset}
      >
        <Form.Control
          placeholder="Oavsett värde"
          onKeyDown={onKeyDownInput}
          {...extraProps}
          isInvalid={isInvalid}
          isValid={Boolean(value) && !isInvalid}
          name={id}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
        />
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

interface SelectOption {
  value: string;
  label: React.ReactNode;
}

// a simple select
export const select = (idAndLabel: IdAndLabel, options: SelectOption[], extendWith = {}): FilterDefinition<string> => ({
  id: idAndLabel[0],
  label: idAndLabel[1],
  Render: props => {
    const { id, label, value, size, validate, onChange:onChangeOuter, extraProps = {} } = props;
    const { placeholder = 'Oavsett värde' } = extraProps;

    const onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeOuter(id, ev.target.value);
    };

    const isInvalid: boolean = useMemo(() => {
      if (value === null || typeof value === 'undefined' || value === '') return false;
      return !options.some(option => option.value === value);
    }, [value, validate]);

    const onReset = useCallback(() => {
      onChangeOuter(id, '');
    }, [id, onChangeOuter]);

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        onReset={onReset}
        resetDisabled={!Boolean(value)}
      >
        <Form.Select
          {...extraProps}
          isInvalid={isInvalid}
          isValid={Boolean(value) && !isInvalid}
          name={id}
          onChange={onChange}
          value={value}
        >
          <option value="">{placeholder}</option>
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </Form.Select>
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

const uuidRegExp = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/;

// string-based, validates a uuid v4
export const uuid = (idAndLabel: IdAndLabel, extendWith = {}): FilterDefinition<string> => input(idAndLabel, {
  validate: (value: any) => {
    if (typeof value !== 'string') return false;
    return uuidRegExp.test(value);
  },
  ...defaultsDeep({}, extendWith, {extraProps: {placeholder: 'Oavsett ID'}}),
});

// a date selector
export const date = (idAndLabel: IdAndLabel, extendWith = {}): FilterDefinition<string> => input(idAndLabel, {
  validate: (value: any) => {
    if (typeof value !== 'string') return false;
    return moment(value, 'YYYY-MM-DD').isValid();
  },
  ...defaultsDeep({}, extendWith, {extraProps: {type: 'date'}}),
});

export const companySearch = (idAndLabel: IdAndLabel, extendWith = {}): FilterDefinition<string> => ({
  id: idAndLabel[0],
  label: idAndLabel[1],
  Render: props => {
    const { id, label, value, size, onChange, extraProps = {} } = props;

    const companySearchManager = useCompanySearchManager({
      onSelect: (orgNumber) => onChange(id, orgNumber),
      isValid: Boolean(value),
    });

    useEffect(() => {
      if (isNull(value)) {
        companySearchManager.clear();
      }
    }, [value]);

    const onReset = useCallback(() => {
      onChange(id, '');
      companySearchManager.clear();
    }, [id, onChange]);

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        onReset={onReset}
        resetDisabled={!Boolean(value)}
      >
        <CompanySearch
          {...extraProps}
          className="w-auto flex-grow-1"
          defaultInputValue={value}
          {...companySearchManager.props}
        />
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

interface Option {
  value: string;
  label: string;
}

export const inputQueryDatalist = (idAndLabel: IdAndLabel, queryOptions: UndefinedInitialDataOptions<Option[]>, extendWith = {}): FilterDefinition<string> => ({
  id: idAndLabel[0],
  label: idAndLabel[1],
  Render: props => {
    const { id, label, size, value:outerValue, onChange:onChangeOuter, extraProps = {} } = props;

    const query = useQuery<Option[]>(queryOptions);

    const listId = useId();
    const list = query.data || [];

    const {
      onFocus,
      onChange,
      onBlur,
      value,
    } = useFocusControl(outerValue, onChangeOuter);

    const isInvalid: boolean = useMemo(() => {
      if (value === null || typeof value === 'undefined' || value === '') return false;
      return !list.some(item => item.value === value);
    }, [value, list]);

    const onReset = useCallback(() => {
      onChangeOuter(id, '');
    }, [id, onChange]);

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        onReset={onReset}
        resetDisabled={!Boolean(value)}
      >
        <datalist id={listId}>
          {list.map(item => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </datalist>
        <Form.Control
          placeholder="Oavsett värde"
          list={listId}
          {...extraProps}
          disabled={query.isLoading}
          isInvalid={isInvalid}
          isValid={Boolean(value) && !isInvalid}
          name={id}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          value={value}
        />
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

interface OptionGroup extends Option {
  children: Option[];
}

export const selectGroupQuery = (idAndLabel: IdAndLabel, queryOptions: UndefinedInitialDataOptions<OptionGroup[]>, extendWith = {}): FilterDefinition<string> => ({
  id: idAndLabel[0],
  label: idAndLabel[1],
  Render: props => {
    const { id, label, value, size, validate, onChange:onChangeOuter, extraProps = {} } = props;
    const { placeholder = 'Oavsett värde' } = extraProps;

    const query = useQuery<OptionGroup[]>(queryOptions);
    const optionGroups = query.data || [];

    const allOptions = useMemo(() => {
      return flatten(optionGroups.map(optionGroup => {
        return optionGroup.children.map(child => child.value);
      }));
    }, [optionGroups]);

    const onChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
      onChangeOuter(id, ev.target.value);
    };

    const isInvalid: boolean = useMemo(() => {
      if (value === null || typeof value === 'undefined' || value === '') return false;
      return !allOptions.includes(value);
    }, [value, validate, allOptions]);

    const onReset = useCallback(() => {
      onChangeOuter(id, '');
    }, [id, onChange]);

    return (
      <TableFilterInputGroup
        name={id}
        label={label ?? id}
        className="flex-grow-1"
        size={size}
        onReset={onReset}
        resetDisabled={!Boolean(value)}
      >
        <Form.Select
          {...extraProps}
          isInvalid={isInvalid}
          isValid={Boolean(value) && !isInvalid}
          name={id}
          onChange={onChange}
          value={value}
        >
          <option value="">{placeholder}</option>
          {optionGroups.map(group => (
            <optgroup label={group.label} key={group.value}>
              {group.children.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </optgroup>
          ))}
        </Form.Select>
      </TableFilterInputGroup>
    );
  },
  ...extendWith,
});

export const userSearch = (idAndLabel: IdAndLabel, extendWith = {}): FilterDefinition<string> => inputQueryDatalist(idAndLabel, {
  queryKey: ['/api/users/list', {limit: 500}],
  select: (data: any) => {
    return data.rows.map((user: IUser) => ({
      value: user.id,
      label: user.name || user.id,
    }));
  },
}, extendWith);

export const companyEventTypeSelectGroup = (idAndLabel: IdAndLabel, extendWith = {}, searchUrl: string = '/api/company_event/event_types'): FilterDefinition<string> => selectGroupQuery(idAndLabel, {
  queryKey: [searchUrl],
  meta: {
    responseExtractor: (response: AxiosResponse<CompanyEventTypesList>) => {
      return response.data.groupedList ?? [];
    },
  },
}, extendWith);
