import React, {useState, useMemo} from 'react';
import axios from 'client/axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Card, Table, Form } from 'react-bootstrap';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import RefreshButton from 'client/buttons/RefreshButton';
import classNames from 'classnames';
import DateFormat from 'client/components/DateFormat';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import { CustomerCompanyMonitoredRowWithRelations } from 'client/customerCompanyMonitored/types';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import { CardBodyErrorAlert } from 'client/card/CardHelpers';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import CompanySearch from 'client/components/CompanySearch';
import LoadingButton from 'client/buttons/LoadingButton';
import ErrorAlert from 'client/components/ErrorAlert';
import useRemount from 'client/hooks/useRemount';

interface CustomerCompanyMonitoredTableProps {
  customerId: string;
  className?: string;
}

interface CustomerCompanyMonitoredQueryResponse {
  rows: CustomerCompanyMonitoredRowWithRelations[];
  limit: number;
}

const CustomerCompanyMonitoredTable: React.FC<CustomerCompanyMonitoredTableProps> = React.memo(function CustomerCompanyMonitoredTable (props: CustomerCompanyMonitoredTableProps) {
  const { className, customerId } = props;

  const [orgNumber, setOrgNumber] = useState<string>('');

  const query = useQuery<CustomerCompanyMonitoredQueryResponse, Error>({
    queryKey: [`/api/customer_company_monitored/${customerId}`],
  });

  const deleteMutation = useMutation<void, Error, string>({
    mutationFn: orgNumber => axios.delete(`/api/customer_company_monitored/${customerId}/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Bevakningen har tagits bort');
      query.refetch();
    },
  });

  const createMutation = useMutation<unknown, Error, string>({
    mutationFn: orgNumber => axios.put(`/api/customer_company_monitored/${customerId}/${orgNumber}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Bevakningen har lagts till');
      setOrgNumber('');
      orgNumberRemount.remount();
      query.refetch();
    },
  });

  const onSubmitCreate = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    return createMutation.mutateAsync(orgNumber);
  };

  const list = query.data?.rows ?? [];
  
  const orgNumberIsAlreadyAdded = useMemo(() => list.some(item => {
    if (!orgNumber) return false;
    return item.org_number === orgNumber;
  }), [list, orgNumber]);

  const orgNumberRemount = useRemount();

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          Kundens bevakade företag
          <div>
            <RefreshButton
              onClick={query.refetch}
              disabled={query.isLoading || query.isRefetching}
              className="px-2"
            />
          </div>
        </Card.Title>
      </Card.Header>
      <CardBodyErrorAlert error={query.error} />
      <CardBodyErrorAlert error={deleteMutation.error} />
      <TableSpinningOverlay isLoading={query.isLoading || query.isRefetching}>
        <div className="table-responsive">
          <Table className="mb-0 border">
            <thead>
              <tr>
                <th>Företag</th>
                <th>Bevakat</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && !list.length && (
                <tr>
                  <td colSpan={3} className="p-2">Kunden har inga bevakade företag</td>
                </tr>
              )}
              {list.map(item => (
                <tr key={[item.org_number, item.customer_id].join('_')}>
                  <td>
                    <CompanyFormatters.CompanyAnchor value={item.cs_basic ?? item.org_number} />
                  </td>
                  <td>
                    <DateFormat format="YYYY-MM-DD HH:mm" value={item.created_at} />
                  </td>
                  <td>
                    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
                      <ConfirmDeleteButton
                        size="sm"
                        className="py-0"
                        onConfirmedDelete={() => deleteMutation.mutateAsync(item.org_number)}
                        confirmMessage="Är du säker på att du vill ta bort företaget från bevakningslistan?"
                        buttonLabel="Ta bort bevakning"
                      />
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
      <Card.Footer className="border border-top-0 py-3">
        <ErrorAlert error={createMutation.error} />
        <form onSubmit={onSubmitCreate}>
          <div className="d-flex gap-2 flex-wrap flex-grow-1">
            {orgNumberRemount.mounted && (
              <CompanySearch
                className="w-auto flex-grow-1"
                onSelect={setOrgNumber}
                defaultInputValue={orgNumber}
              />
            )}
            <LoadingButton
              type="submit"
              disabled={!orgNumber || createMutation.isPending || orgNumberIsAlreadyAdded}
              isLoading={createMutation.isPending}
            >
              Lägg till bevakning
            </LoadingButton>
          </div>
          {orgNumberIsAlreadyAdded && (
            <Form.Text className="text-danger">Företaget är redan bevakat</Form.Text>
          )}
        </form>
      </Card.Footer>
    </Card>
  );
});
export default CustomerCompanyMonitoredTable;
