import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import HasCompanyMonitoringGuard from 'client/guards/HasCompanyMonitoringGuard';

export default function NavbarMainMenu () {
  return (
    <Nav className="me-auto">
      <Nav.Link as={NavLink} to="/" exact>
        Mina rapporter
      </Nav.Link>
      <HasCompanyMonitoringGuard>
        <Nav.Link as={NavLink} to="/monitoring" exact>
          Mina bevakningar
        </Nav.Link>
        <Nav.Link as={NavLink} to="/company_event" exact>
          Företagshändelser
        </Nav.Link>
      </HasCompanyMonitoringGuard>
      {/* <Nav.Link as={NavLink} to="/companies" exact> */}
      {/*   Alla företag */}
      {/* </Nav.Link> */}
    </Nav>
  );
}
