import React from 'react';
import useAuth from 'client/hooks/useAuth';
import { UserRole } from 'client/user/types';

interface RoleGuardProps extends React.PropsWithChildren {
  role: UserRole | UserRole[] | null;
  invert?: boolean;
}

const RoleGuard: React.FC<RoleGuardProps> = React.memo(function RoleGuard (props: RoleGuardProps) {
  const { role, invert = false, children } = props;
  const auth = useAuth();
  if (role === null) return <>{children}</>;
  if (invert && auth.isUserRole(role)) {
    return null;
  } else if (!invert && !auth.isUserRole(role)) {
    return null;
  }
  return (
    <>
      {children}
    </>
  );
});

export default RoleGuard;
