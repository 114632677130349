import React from 'react';
import PageContainer from 'client/components/PageContainer';
import { Helmet } from 'react-helmet';
import PageHeader from 'client/components/PageHeader';
import { Card, Row, Col } from 'react-bootstrap';
import CompanyMonitoredSettings from 'client/asCustomer/CompanyMonitoredSettings';
import CompanyMonitoredTableCard from 'client/asCustomer/CompanyMonitoredTableCard';

export default function CustomerCompanyMonitoredPage () {
  return (
    <PageContainer fluid="xl">
      <Helmet>
        <title>Mina företagsbevakningar</title>
      </Helmet>
      <PageHeader>
        Mina företagsbevakningar
      </PageHeader>
      <Card className="p-4 pb-0 mb-4">
        <Row>
          <Col lg={6}>
            <p>
              På den här sidan kan du lägga till bevakningar på svenska aktiebolag. Du kan hitta företag via sökning på organisationsnumret eller företagsnamnet.
            </p>
            <p>
              Ditt användarkonto har blivit tilldelad ett antal slottar som motsvarar hur många företag totalt som kan läggas upp för bevakning. Kontakta oss på Calculate om du vill justera din gräns.
            </p>
            <p>
              Det är möjligt att ändra kontaktfrekvens mellan varje dag, vecka eller månad. Tänk på att om ingenting har hänt på dina bevakade företag så får du inget meddelande alls oavsett vad du ställer in kontaktfrekvensen till.
            </p>
          </Col>
          <Col lg={6}>
            <p>
              Du kan välja att bli automatiskt kontaktad när något av följande inträffar på ett bevakat företag:
            </p>
            <ul className="small">
              <li>Företagsstatus (konkurs, F-skatt, moms, etc)</li>
              <li>Kredithändelser (rating, betalningsanmärkningar, skuldsaldo, etc)</li>
              <li>Styrelseförändringar (vem har tillträtt eller avgått)</li>
              <li>Särskilda risker</li>
              <li>Nytt bokslut och förändrade nyckeltal</li>
            </ul>
            <p>
              Du kan själv redigera exakt vilka händelser som leder till att du blir meddelad.
            </p>
          </Col>
        </Row>
      </Card>
      <CompanyMonitoredSettings className="mb-4" />
      <CompanyMonitoredTableCard />
    </PageContainer>
  );
}
