import { useMemo, useState, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PageHeader from 'client/components/PageHeader';
import ErrorAlert from 'client/components/ErrorAlert';
import { ColumnDefinition, FilterDefinition } from 'client/table/types';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import * as CompanyFormatters from 'client/company/CompanyFormatters';
import { CompanyMonitoredWithRelationsRow } from 'client/companyMonitored/types';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import TableFilterVirtual from 'client/table/TableFilterVirtual';
import { TableCardFooterWithPagination } from 'client/table/TableUtils';
import * as CompanyMonitoredFormatters from 'client/companyMonitored/CompanyMonitoredFormatters';
import { Search } from 'react-feather';
import { TableProvider } from 'client/contexts/TableContext';

export interface CompanyMonitoredTableFilterParams {
  org_number?: string;
  portfolio_name?: string;
}

interface CompanyMonitoredWithId extends CompanyMonitoredWithRelationsRow {
  id: string;
}

interface ListData {
  rows: CompanyMonitoredWithRelationsRow[];
  total_rows: number;
}

const defaultFilterParams = {};

const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
  ...defaultFilterParams,
};

export default function CompanyMonitoredTablePage () {
  const {
    setFilterParams,
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
    params,
  } = useTableState({
    defaultState,
  });

  const query = useQuery<ListData, Error>({
    queryKey: ['/api/company_monitored/list', params],
    placeholderData: keepPreviousData,
  });

  const rows: CompanyMonitoredWithId[] = useMemo(() => {
    if (!Array.isArray(query.data?.rows)) return [];
    return (query.data?.rows || []).map(row => ({...row, id: row.org_number}));
  }, [query.data]);

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const allIds = useMemo(() => rows.map(item => item.id), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(query.data?.total_rows ?? 0);
  }, [query.data?.total_rows]);

  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);
  const [columnOrder, setColumnOrder] = useState(() => columnDefinitions.filter(c => c.show).map(c => c.id));

  return (
    <PageContainer fluid>
      <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
        <Helmet>
          <title>Bevakade företag</title>
        </Helmet>
        <PageHeader className="mt-4 mb-2">Bevakade företag</PageHeader>
        <p className="mb-4">
          Denna tabell visar alla företag som är eller har varit bevakade.
          De aktiva bevakningarna på företag i tabellen synkroniseras till en eller flera
          "bevakningsportföljer" som återfinns i Creditsafes system.
        </p>
        <ErrorAlert className="my-3" error={query.error} />
        <Card>
          <Card.Header className="p-3">
            <TableFilterVirtual
              isLoading={query.isLoading || query.isRefetching}
              filterDefinitions={filterDefinitions}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              columnDefinitions={columnDefinitions}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}
              onReload={query.refetch}
              onReset={filterReset}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={query.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnOrder={columnOrder}
                isInitialLoading={query.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={tablePagination?.totalCountOfRows || rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultTableColumns = [
  'select',
  'org_number',
  'status',
  // 'portfolio_name',
  'synced_at',
  'created_at',
  // 'updated_at',
  'actions',
];

const columnSortable = [
  'org_number',
  'portfolio_name',
  'synced_at',
  'created_at',
  'updated_at',
];

type FilterDefinitionFactory = () => FilterDefinition<any>[];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.companySearch(['org_number', 'Företag']),
  filterDefs.input(['portfolio_name', 'Portfölj'], {
    extraProps: {placeholder: 'Oavsett portfölj'},
  }),
];

type ColumnDefinitionFactory = () => ColumnDefinition<CompanyMonitoredWithId>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  columnDefs.select(),
  columnDefs.cell(['org_number', 'Företag'], props => (
    <CompanyFormatters.CompanyAnchor value={props.row.cs_basic ?? props.row.org_number} />
  )),
  columnDefs.cell(['status', 'Status'], props => (
    <CompanyMonitoredFormatters.StatusLabel value={props.row} />
  )),
  columnDefs.simple(['portfolio_name', 'Portfölj']),

  columnDefs.date(['synced_at', 'Synkad']),
  columnDefs.date(['created_at', 'Skapad']),
  columnDefs.date(['updated_at', 'Uppdaterad']),

  columnDefs.actions(props => (
    <>
      <div className="d-flex gap-1 flex-wrap align-items-center justify-content-end">
        <Link
          className="btn btn-outline-primary btn-sm py-0 d-inline-flex gap-1 align-items-center px-1"
          to={`/company_monitored_update?org_number=${props.row.org_number}`}
        >
          <Search size={14} />
          Uppdateringar
        </Link>
      </div>
    </>
  )),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
