import React, { useState, useEffect } from 'react';
import axios from 'client/axios';
import { useQuery } from '@tanstack/react-query';
import { useLocalStorage } from 'client/hooks/useStorage';
import { useHistory } from 'react-router-dom';
import { UserRole } from 'client/user/types';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as errorUtils from 'client/utils/errors';
import useLastLocation from 'client/hooks/useLastLocation';

export type AuthUserTypes = 'customer' | 'user';

interface AuthUser {
  id: string;
  name: string;
  email: string;
  role: UserRole;
  user_type: 'user';
  company_monitored_limit: null | number;
}

interface AuthCustomer {
  id: string;
  name: string;
  email: string;
  user_type: 'customer';
  company_monitored_limit: null | number;
}

export interface Credentials {
  username: string;
  password: string;
  remember?: boolean;
}

export type AccountResponseBody = AuthUser | AuthCustomer;

interface AuthState {
  isAuthenticated: boolean;
  isInitialized: boolean;
  isCustomer: boolean;
  isUser: boolean;
  userType: AuthUserTypes | null;
  customer: null | AuthCustomer;
  customerId: string; // will be empty when not authenticated
  user: null | AuthUser;
  userId: string; // will be empty when not authenticated
  hasSwitched: boolean;
}

export interface AuthContextType extends AuthState {
  login: (credentials: Credentials) => Promise<boolean>;
  logout: () => Promise<void>;
  accessToken: string | null;
  isUserRole: (role:(UserRole | UserRole[])) => boolean;
  isUserType: (type: AuthUserTypes) => boolean;
  setStateByLogin: (result: LoginResponseBody) => void;
  setAccount: (newAccount: AccountResponseBody) => void;
  switchTo: (type: string, id: string) => Promise<any>;
  switchBack: () => Promise<any>;
}

interface LoginCustomerResponseBody {
  access_token: string;
  user_type: 'customer';
  customer: AuthCustomer;
}

interface LoginUserResponseBody {
  access_token: string;
  user_type: 'user';
  role: string;
  user: AuthUser;
}

type LoginResponseBody = LoginUserResponseBody | LoginCustomerResponseBody;

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  isCustomer: false,
  isUser: false,
  userType: null,
  user: null,
  userId: '',
  customer: null,
  customerId: '',
  hasSwitched: false,
};

const AuthContext = React.createContext<AuthContextType>({
  ...initialState,
  accessToken: null,
  login: async () => false,
  logout: async () => {},
  isUserRole: () => false,
  isUserType: () => false,
  setAccount: () => {},
  setStateByLogin: () => {},
  switchTo: async () => false,
  switchBack: async () => {},
});

const AuthProvider: React.FC<React.PropsWithChildren> = React.memo(function AuthProvider (props: React.PropsWithChildren) {
  const { children } = props;

  const history = useHistory();

  const [prevAccessToken, setPrevAccessToken] = useLocalStorage<string | null>('CalculateAccessTokenPrev', null);
  const [accessToken, setAccessToken] = useLocalStorage<string | null>('CalculateAccessToken', null);

  const [state, setState] = useState<AuthState>({
    ...initialState,
    hasSwitched: Boolean(prevAccessToken),
  });

  const { saveLastLocation } = useLastLocation();

  // keep "hasSwitched" in state in sync with prevAccessToken
  useEffect(() => {
    setState(prevState => ({...prevState, hasSwitched: Boolean(prevAccessToken)}));
  }, [prevAccessToken]);

  // keep the axios default authorization header up to date
  useEffect(() => {
    syncAccessToken(accessToken);
  }, [accessToken]);

  useEffect(() => {
    const id = axios.interceptors.response.use(
      response => response,
      err => {
        if (err?.response?.status !== 401) {
          throw err;
        }

        // so there was a problem with access
        // can be no token, wrong permissions, a lot of stuff
        saveLastLocation();
        initializedButNotLoggedIn();
        history.push('/login');
      },
    );
    return () => {
      axios.interceptors.response.eject(id);
    };
  }, [axios]);

  const initializedButNotLoggedIn = () => {
    setAccessToken(null);
    setState({...initialState, isInitialized: true});
  };

  const syncAccessToken = (accessToken: null | string) => {
    if (accessToken) {
      const header = `Bearer ${accessToken}`;
      axios.defaults.headers.common['Authorization'] = header;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  const setAccount = (account: AccountResponseBody) => {
    const { user_type:userType } = account;
    setState(prevState => ({
      ...prevState,
      isInitialized: true,
      isAuthenticated: true,
      isCustomer: userType === 'customer',
      isUser: userType === 'user',
      userType,
      user: userType === 'user' ? account : null,
      userId: userType === 'user' ? account.id : '',
      customer: userType === 'customer' ? account : null,
      customerId: userType === 'customer' ? account.id : '',
    }));
  };

  const accountQuery = useQuery<AccountResponseBody | null>({
    queryKey: ['account'],
    queryFn: () => accessToken ? axios.get('/api/account').then(r => r.data) : null,
  });

  useEffect(() => {
    if (accountQuery.data === null) {
      initializedButNotLoggedIn();
      return;
    }
    if (!accountQuery.data) return;
    setAccount(accountQuery.data);
  }, [accountQuery.data]);

  useEffect(() => {
    if (!accountQuery.isError) return;

    // clear token here, we were obviously not logged in
    saveLastLocation();
    initializedButNotLoggedIn();
  }, [accountQuery.isError]);

  const setStateByLogin = (result: LoginResponseBody, extendWith: Partial<AuthState> = {}) => {
    const userType = result.user_type;
    setAccessToken(result.access_token);
    setState(prevState => ({
      ...prevState,
      isInitialized: true,
      isAuthenticated: true,
      isCustomer: userType === 'customer',
      isUser: userType === 'user',
      userType,
      user: userType === 'user' ? result.user : null,
      userId: userType === 'user' ? result.user.id : '',
      customer: userType === 'customer' ? result.customer : null,
      customerId: userType === 'customer' ? result.customer.id : '',
      ...extendWith,
    }));
  };

  const login = async (credentials: Credentials) => {
    try {
      const data = await axios.post<LoginResponseBody>('/api/auth/login', credentials).then(r => r.data);
      setStateByLogin(data);
      return true;
    } catch (err: any) {
      if (err.response?.status === 400) return false;
      throw err;
    }
  };

  const logout = async () => {
    await axios.post('/api/logout');
    initializedButNotLoggedIn();
    history.push('/login');
  };

  // switches to another user or customer by their id (implemented as a new login)
  const switchTo = async (type: string, id: string) => {
    try {
      const url = `/api/auth/switch/${type}/${id}`;
      const data = await axios.post<LoginResponseBody>(url, {}).then(r => r.data);

      setState(initialState);
      setPrevAccessToken(accessToken);
      setAccessToken(data.access_token);
      syncAccessToken(data.access_token);

      accountQuery.refetch().then(response => {
        if (!response.data) return history.push('/');
        setAccount(response.data);
        return history.push('/');
      });
    } catch (err: any) {
      requestCallbacks.onError(errorUtils.errorToMessage(err, 'Byte misslyckades'));
      throw err;
    }
  };

  // returns to the original user (reset auth state, swap to old token, refetch account)
  const switchBack = async () => {
    try {
      const wasCustomer = state.isCustomer;

      setAccessToken(prevAccessToken);
      setPrevAccessToken(null);
      setState(initialState);
      syncAccessToken(prevAccessToken);

      accountQuery.refetch().then(response => {
        if (!response.data) return history.push('/');
        setAccount(response.data);
        return history.push(wasCustomer ? '/customers' : '/users');
      });
    } catch (err: any) {
      requestCallbacks.onError(errorUtils.errorToMessage(err, 'Återgång misslyckades'));
      throw err;
    }
  };

  const isUserRole = (role: (UserRole | UserRole[])): boolean => {
    if (!state.isAuthenticated) return false;
    if (!state.isUser) return false;
    const userRole = state.user?.role;
    if (!userRole) return false;
    if (Array.isArray(role)) {
      return role.includes(userRole);
    }
    return userRole === role;
  };

  const isUserType = (type: AuthUserTypes): boolean => {
    if (!state.isAuthenticated) return false;
    if (type === 'user') return state.isUser;
    if (type === 'customer') return state.isCustomer;
    return false;
  };

  const value = {
    ...state,
    accessToken,
    login,
    logout,
    setAccount,
    setStateByLogin,
    switchTo,
    switchBack,
    isUserRole,
    isUserType,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
});

export { AuthContext, AuthProvider };
