import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

interface PageHeaderBreadcrumb {
  title: string;
  url?: string;
}

interface PageHeaderProps extends React.PropsWithChildren {
  className?: string;
  breadcrumbs?: PageHeaderBreadcrumb[];
}

function PageHeader (props: PageHeaderProps) {
  const { children, className = 'my-4', breadcrumbs } = props;
  return (
    <div className={className}>
      <h2 className="d-flex align-items-center mb-1 justify-content-between justify-content-sm-between gap-2 flex-wrap">
        {children}
      </h2>
      {breadcrumbs?.length && (
        <Breadcrumb>
          {breadcrumbs.map(({ url, title }, index) => (
            <Breadcrumb.Item
              key={index}
              linkAs={Link}
              linkProps={{to: url}}
              active={!url}
            >
              {title}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      )}
    </div>
  );
}

export default React.memo(PageHeader);
