import React from 'react';
import PageContainer from 'client/components/PageContainer';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useParams, useHistory } from 'react-router-dom';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import * as CustomerSurveyFormatters from 'client/customerSurvey/CustomerSurveyFormatters';
import { Card, Tab, Nav } from 'react-bootstrap';
import TabNavLink from 'client/tab/TabHelpers';
import BlockSpinner from 'client/spinners/BlockSpinner';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import CustomerSurveyForm, { UpdateCustomerSurveyFormFields } from 'client/customerSurvey/CustomerSurveyForm';
import * as formUtils from 'client/utils/form';
import { Helmet } from 'react-helmet';
import SaveButton from 'client/buttons/SaveButton';
import { maybeAxiosErrorToErrorMap } from 'client/utils/errors';
import ConfirmDeleteButton from 'client/buttons/ConfirmDeleteButton';
import { CardHeaderObject } from 'client/card/CardHelpers';
import PageHeader from 'client/components/PageHeader';
import { CustomerSurveyRow, CustomerSurveyWithRelationsRow } from 'client/customerSurvey/types';
import {ExternalLink} from 'react-feather';
import CustomerSurveyEmailsTable from 'client/customerSurvey/CustomerSurveyEmailsTable';
import CustomerSurveyPropertiesTable from 'client/customerSurvey/CustomerSurveyPropertiesTable';

interface CustomerSurveyEditPageParams {
  customerSurveyId: string;
  tab?: string;
}

export default function CustomerSurveyEditPage () {
  const { customerSurveyId, tab = 'form' } = useParams<CustomerSurveyEditPageParams>();

  const history = useHistory();

  const readQuery = useQuery<CustomerSurveyWithRelationsRow, Error>({
    queryKey: [`/api/customer_surveys/${customerSurveyId}`],
  });

  const updateMutation = useMutation<CustomerSurveyRow, Error, Partial<CustomerSurveyRow>>({
    mutationFn: vars => axios.patch(`/api/customer_surveys/${customerSurveyId}`, vars).then(r => r.data),
    onSuccess: () => {
      requestCallbacks.onSuccess('Undersökningen har uppdaterats');
      readQuery.refetch();
    },
  });

  const deleteMutation = useMutation<void, Error>({
    mutationFn: () => axios.delete(`/api/customer_surveys/${customerSurveyId}`),
    onSuccess: () => {
      requestCallbacks.onSuccess('Undersökningen har raderats');
      history.push('/customer_survey');
    },
  });

  const onRefresh = () => {
    return readQuery.refetch();
  };

  const onSubmit = (form: UpdateCustomerSurveyFormFields, helpers: FormikHelpers<UpdateCustomerSurveyFormFields>) => {
    const changedCustomerSurvey = formValuesToCustomerSurvey(form);
    const update = formUtils.changes({...customerSurvey} as Record<string, any>, changedCustomerSurvey);
    updateMutation.mutateAsync(update)
      .then(updatedCustomerSurvey => {
        const newValues = customerSurveyToFormValues(updatedCustomerSurvey);
        helpers.resetForm({values: newValues});
      }).catch(err => {
        helpers.setSubmitting(false);
        const errorMap = maybeAxiosErrorToErrorMap(err);
        if (errorMap) {
          helpers.setErrors(errorMap);
          return;
        }
        throw err;
      });
  };

  const customerSurvey = readQuery.data;

  return (
    <PageContainer fluid="sm">
      <Helmet>
        <title>Redigera undersökning "{customerSurveyId}"</title>
      </Helmet>
      <PageHeader breadcrumbs={[{title: 'Undersökningar', url: '/customer_survey'}, {title: customerSurveyId}]}>
        Redigera undersökning
      </PageHeader>
      <Card>
        <CardHeaderObject
          objectId={customerSurveyId}
          onRefresh={onRefresh}
          status={<>
            {customerSurvey && (
              <CustomerSurveyFormatters.StatusLabel value={customerSurvey.status} />
            )}
          </>}
        />
        <BlockSpinner isLoading={readQuery.isLoading} className="m-3" />
        <ErrorAlert error={readQuery.error} className="m-3" />
        {readQuery.isSuccess && customerSurvey && (
          <>
            <Tab.Container
              defaultActiveKey="overview"
              activeKey={tab}
              transition={false}
              mountOnEnter
              unmountOnExit
            >
              <Nav className="nav-tabs pt-3 px-3">
                <TabNavLink
                  eventKey="overview"
                  title="Översikt"
                />
                <TabNavLink
                  eventKey="form"
                  title="Formulär"
                />
                <TabNavLink
                  eventKey="emails"
                  title="E-post"
                />
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="overview" className="p-4">
                  <CustomerSurveyPropertiesTable
                    className="mb-0 border"
                    customerSurvey={customerSurvey}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="form" className="p-4">
                  <Formik initialValues={customerSurveyToFormValues(customerSurvey)} onSubmit={onSubmit}>
                    {formikBag => (
                      <FormikForm>
                        <Card>
                          <Card.Body className="pb-1">
                            <CustomerSurveyForm formikBag={formikBag} />
                            <ErrorAlert error={updateMutation.error} />
                          </Card.Body>
                          <Card.Footer className="d-flex gap-2 p-3">
                            <SaveButton
                              type="submit"
                              isLoading={updateMutation.isPending}
                              disabled={!formikBag.isValid || formikBag.isSubmitting || !Object.keys(formikBag.touched).length}
                            />
                          </Card.Footer>
                        </Card>
                      </FormikForm>
                    )}
                  </Formik>
                </Tab.Pane>
                <Tab.Pane eventKey="emails" className="p-4">
                  <CustomerSurveyEmailsTable
                    customerSurveyId={customerSurveyId}
                    onSuccessSendEmail={onRefresh}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
            <Card.Footer className="p-3">
              <ErrorAlert error={deleteMutation.error} />
              <div className="d-flex gap-2">
                <InspectObjectModalButton object={customerSurvey} size="lg" className="px-2" />
                <ConfirmDeleteButton
                  onConfirmedDelete={deleteMutation.mutateAsync}
                  confirmMessage="Är du säker på att du vill radera undersökningen permanent?"
                  buttonLabel="Radera undersökningen"
                />
                <a
                  href={`/survey/${customerSurvey?.id}?open=0`}
                  className="btn btn-outline-primary d-flex gap-1 align-items-center"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ExternalLink size={18} />{' '}
                  Öppna svarsformulär
                </a>
              </div>
            </Card.Footer>
          </>
        )}
      </Card>
    </PageContainer>
  );
}

function customerSurveyToFormValues (customerSurvey?: CustomerSurveyRow): UpdateCustomerSurveyFormFields {
  const {
    status = 'unsent',
  } = customerSurvey || {};
  return {
    status,
  };
}

function formValuesToCustomerSurvey (form: UpdateCustomerSurveyFormFields): Partial<CustomerSurveyRow> {
  const { status } = form;
  const customerSurvey = { status } as Partial<CustomerSurveyRow>;
  return customerSurvey;
}
