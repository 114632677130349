import { ICsBasic } from 'client/cs/types';
import Anchor from 'client/anchors/Anchor';
import { Tooltip, OverlayTrigger, BadgeProps } from 'react-bootstrap';
import StatusBadge from 'client/components/StatusBadge';
import * as CsFormatters from 'client/cs/CsFormatters';

interface CompanyAnchorProps {
  value: null | string | Pick<ICsBasic, 'org_number' | 'company_name'>;
}

export function CompanyAnchor (props: CompanyAnchorProps) {
  const { value } = props;
  const orgNumber = typeof value === 'string' ? value : value?.org_number;
  if (!orgNumber) return null;
  return (
    <Anchor to={`/company/${orgNumber}/overview`}>
      <CompanyName value={value as CompanyNameProps['value']} />
    </Anchor>
  );
}

interface CompanyNameProps {
  value: string | Pick<ICsBasic, 'org_number' | 'company_name'>;
}

export function CompanyName (props: CompanyNameProps) {
  const { value } = props;
  const companyName = typeof value === 'string' ? value : value?.company_name ?? '';
  return (
    <>{companyName}</>
  );
}

interface CompanySniCodeProps {
  value: Partial<ICsBasic>;
}

export function CompanySniCode (props: CompanySniCodeProps) {
  const { value: { sni_code, sni_text } } = props;
  if (!sni_code) return null;
  return (
    <OverlayTrigger placement="auto" overlay={<Tooltip style={{position: 'fixed'}}>{sni_text ?? sni_code}</Tooltip>}>
      <code className="border rounded d-inline-flex align-items-center gap-1 px-1">
        {sni_code}
      </code>
    </OverlayTrigger>
  );
}

interface CompanyFTaxDescriptionProps {
  value: ICsBasic;
}

export function CompanyFTaxDescription (props: CompanyFTaxDescriptionProps) {
  const { value } = props;
  const { f_tax_yes_no } = value;
  return (
    <CsFormatters.CsYesNo value={f_tax_yes_no} />
  );
}

interface CompanyFTaxRegSpecProps {
  value: ICsBasic['f_tax_reg_spec'];
}

export function CompanyFTaxRegSpec (props: CompanyFTaxRegSpecProps) {
  const { value } = props;
  switch (value) {
    default: return <>{value}</>;
    case '0': return <>Aldrig haft F-skatt</>;
    case '1': return <>Registrerad för F-skatt</>;
    case '9': return <>Avregistrerad för F-skatt</>;
  }
}

interface CompanyMomsDescriptionProps {
  value: ICsBasic;
}

export function CompanyMomsDescription (props: CompanyMomsDescriptionProps) {
  const { value } = props;
  const { moms_yes_no } = value;
  return (
    <CsFormatters.CsYesNo value={moms_yes_no} />
  );
}

interface StatusLabelProps extends BadgeProps {
  value: ICsBasic['status_text_high'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  switch (value) {
    default: return <StatusBadge {...restOfProps}>{String(value)}</StatusBadge>;
    case 'Aktivt':
    case 'Drivs i Kommission':
    case 'Lagerbolag':
      return <StatusBadge bg="success" {...restOfProps}>{value}</StatusBadge>;
    case 'Ingen statusinformation finns':
    case 'Vilande enligt senaste årsredovisning':
      return <StatusBadge bg="secondary" {...restOfProps}>{value}</StatusBadge>;
    case 'Fusion avslutad':
    case 'Likvidation avslutad':
      return <StatusBadge bg="primary" {...restOfProps}>{value}</StatusBadge>;
    case 'Avfört':
    case 'Avregistrerat':
    case 'Konkursansökan':
    case 'Konkursbeslut':
    case 'Konkurshistorik':
      return <StatusBadge bg="danger" {...restOfProps}>{value}</StatusBadge>;
    case 'Fusion inledd':
    case 'Fusion tillåten':
    case 'Likvidation beslutad':
    case 'Rekonstruktion inledd':
      return <StatusBadge bg="warning" {...restOfProps}>{value}</StatusBadge>;
    case 'Inaktivt':
    case 'Konkurs avslutad':
    case 'Konkurs avslutad med överskott':
    case 'Likvidation fortsätter':
      return <StatusBadge bg="dark" {...restOfProps}>{value}</StatusBadge>;
  }
}

export function AccountToTitle (props: { value: string }) {
  return props.value.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
}
