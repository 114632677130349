import React, { useState } from 'react';
import { Formik, Form as FormikForm, Field, FormikHelpers, FormikProps } from 'formik';
import { errorToMessage } from 'client/utils/errors';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import IdProvider from 'client/components/IdProvider';
import SaveButton from 'client/buttons/SaveButton';
import { Card, Form, Row, Col } from 'react-bootstrap';
import useAuth from 'client/hooks/useAuth';
import { useMutation } from '@tanstack/react-query';
import * as formUtils from 'client/utils/form';
import { AccountResponseBody, AuthUserTypes } from 'client/contexts/AuthContext';
import { maybeAxiosErrorToErrorMap } from 'client/utils/errors';
import AccountChangePasswordFormFields, { AccountChangePasswordFormFieldsType, validateAccountChangePasswordFormFields } from 'client/account/AccountChangePasswordFormFields';

interface IAccountFormInitialSettings {
  name: string;
  email: string;
  user_type: AuthUserTypes;
}

interface IAccountFormData extends IAccountFormInitialSettings {
  change_password: boolean;
  password: string;
  password_repeat: string;
}

interface IAccountForm {
  initialSettings: IAccountFormInitialSettings;
}

const AccountForm: React.FC<IAccountForm> = React.memo(function AccountForm (props: IAccountForm) {
  const { initialSettings } = props;
  const [formError, setFormError] = useState<string>('');

  const [settings, setSettings] = useState<IAccountFormInitialSettings>(initialSettings);

  const auth = useAuth();

  const updateAccountMutation = useMutation<AccountResponseBody, Error, IAccountFormData>({
    mutationKey: ['AccountFormMutation'],
    mutationFn: form => {
      const update = formUtils.changes(settings, form) as IAccountFormData;
      if (form.change_password && form.password) update.password = form.password;
      return axios.patch('/api/account', update).then(r => r.data);
    },
    onSuccess: newAccount => {
      auth.setAccount(newAccount);
      requestCallbacks.onSuccess('Kontoinställningarna uppdaterades');
    },
    onError: err => {
      setFormError(errorToMessage(err));
    },
  });

  const validateForm = (values: IAccountFormData) => {
    const errors: any = validateAccountChangePasswordFormFields(values);
    return errors;
  };

  const onSubmit = (form: IAccountFormData, helpers: FormikHelpers<IAccountFormData>) => {
    setFormError('');
    return updateAccountMutation.mutateAsync(form).then(updatedSettings => {
      const newValues = {
        ...form,
        ...updatedSettings,
        change_password: false,
        password: '',
        password_repeat: '',
      };
      helpers.resetForm({values: newValues});
      setSettings(updatedSettings);
    }).catch(err => {
      helpers.setSubmitting(false);
      const errorMap = maybeAxiosErrorToErrorMap(err);
      if (errorMap) {
        helpers.setErrors(errorMap);
        return;
      }
      throw err;
    });
  };

  const initialFormValues = {
    ...settings,
    password: '',
    password_repeat: '',
    change_password: false,
  };

  return (
      <Formik
        initialValues={initialFormValues}
        onSubmit={onSubmit}
        validate={validateForm}
      >
        {formikBag => (
          <FormikForm>
            <Card.Body className="pb-1">
              <Row>
                <Col md={6} sm={12}>

                  <IdProvider>
                    {id => (
                      <Form.Group className="mb-3">
                        <Form.Label htmlFor={id}>
                          Namn
                        </Form.Label>
                        <Field
                          as={Form.Control}
                          id={id}
                          name="name"
                          placeholder="Ange ett namn"
                          isInvalid={Boolean(formikBag.errors.name)}
                          required
                        />
                      </Form.Group>
                    )}
                  </IdProvider>
                  <IdProvider>
                    {id => (
                      <Form.Group className="mb-4">
                        <Form.Label htmlFor={id}>
                          E-post
                        </Form.Label>
                        <Field
                          as={Form.Control}
                          id={id}
                          name="email"
                          placeholder="Ange en e-postadress"
                          isInvalid={Boolean(formikBag.errors.email)}
                          required
                        />
                      </Form.Group>
                    )}
                  </IdProvider>
                  <AccountChangePasswordFormFields
                    isOwnPassword
                    formikBag={formikBag as unknown as FormikProps<AccountChangePasswordFormFieldsType>}
                    checkboxLabel="Ändra lösenord för att logga in"
                  />
                </Col>
              </Row>
              <ErrorAlert error={formError} />
            </Card.Body>
            <Card.Footer className="d-flex justify-content-between align-items-baseline py-3">
              <SaveButton
                type="submit"
                isLoading={updateAccountMutation.isPending}
                disabled={!formikBag.isValid || formikBag.isSubmitting || !Object.keys(formikBag.touched).length}
              />
            </Card.Footer>
          </FormikForm>
        )}
      </Formik>
  );
});

export default AccountForm;
