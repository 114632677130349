import React from 'react';
import PageContainer from 'client/components/PageContainer';
import { PdfView } from 'client/pdf/PdfView';

export default function PdfPage () {
  return (
    <div className="clc-pdf-player">
      <PageContainer fluid="sm" className="p-4">
        <div className="d-flex justify-content-center">
          <PdfView file={'/report.pdf'} />
        </div>
      </PageContainer>
    </div>
  );
}
