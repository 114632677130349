import React from 'react';
import { isEqual } from 'lodash';
import {FieldValidator} from 'formik';

export type TChangeValue<T = any, K = string> = (name: K, value: T) => void | Promise<any>;

export function preventDefaultSubmit (ev: React.FormEvent<HTMLFormElement>) {
  ev.preventDefault();
}

// returns all fields in b that exist in a, but has a changed value in b
export function changes <T extends Record<string, any> = Record<string, any>> (a: Partial<T>, b: Partial<T>): Partial<T> {
  const changes: Partial<T> = {};
  for (const aKey in a) {
    if (aKey in b && !isEqual(a[aKey], b[aKey])) {
      changes[aKey] = b[aKey];
    }
  }
  return changes;
}

// returns the number of lines in the string
// may be used to set the "rows" attribute of a textarea to be adaptive
export function numberOfLines (str: string): number {
  return str.split('\n').length;
}


interface InputAttributes {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  // TODO fill in more, step, min, max
}

export function inputPropsToFormikValidate (attrs: InputAttributes): FieldValidator {
  const { required, minLength, maxLength, pattern } = attrs;
  return function (value) {
    if (required && !value) return 'Värdet måste anges';
    if (typeof minLength === 'number' && minLength > 0 && value?.length < minLength) return `Värdet måste vara minst ${minLength} tecken långt`;
    if (typeof maxLength === 'number' && maxLength > 0 && value?.length > maxLength) return `Värdet får vara max ${minLength} tecken långt`;
    if (typeof pattern === 'string' && !(new RegExp(pattern)).test(value)) return `Värdet måste matcha önskat format: ${pattern}`;
    return '';
  };
}

interface SelectAttributes {
  required?: boolean;
}

export function selectPropsToFormikValidate (attrs: SelectAttributes): FieldValidator {
  const { required } = attrs;
  return function (value) {
    if (required && !value) return 'Värdet måste anges';
    return '';
  };
}

interface CheckAttributes {
  required?: boolean;
}

export function checkPropsToFormikValidate (attrs: CheckAttributes): FieldValidator {
  const { required } = attrs;
  return function (value) {
    if (required && !value) return 'Värdet måste väljas';
    return '';
  };
}
