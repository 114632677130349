import { useMemo, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import ErrorAlert from 'client/components/ErrorAlert';
import PageHeader from 'client/components/PageHeader';
import TableVirtual from 'client/table/TableVirtual';
import TableFilterVirtual from 'client/table/TableFilterVirtual';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import { Card } from 'react-bootstrap';
import { ColumnDefinitionFactory, FilterDefinitionFactory } from 'client/table/types';
import { Helmet } from 'react-helmet';
import { IEmail } from 'client/email/types';
import { TableCardFooterWithPagination, TableSpinningOverlay } from 'client/table/TableUtils';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import * as EmailFormatters from 'client/email/EmailFormatters';
import * as CustomerFormatters from 'client/customer/CustomerFormatters';
import * as CustomerSurveyFormatters from 'client/customerSurvey/CustomerSurveyFormatters';
import * as CompanyEventBatchFormatters from 'client/companyEventBatch/CompanyEventBatchFormatters';
import * as UserFormatters from 'client/user/UserFormatters';
import { TableProvider } from 'client/contexts/TableContext';

interface ListData {
  rows: IEmail[];
  total_rows: number;
}

const defaultFilterParams = {};
const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
  ...defaultFilterParams,
};

export default function EmailTablePage () {
  const {
    setFilterParams,
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
    tableColumns,
    setTableColumns,
    params,
  } = useTableState({
    defaultState,
    defaultTableColumns,
  });

  const listQuery = useQuery<ListData, Error>({
    queryKey: ['/api/emails/list', params],
    placeholderData: keepPreviousData,
  });

  useEffect(() => {
    const count = listQuery.data?.total_rows ?? 0;
    tablePagination.onChangeTotalCountOfRows(count);
  }, [listQuery.data?.total_rows]);

  const rows = listQuery.data?.rows ?? [];

  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);

  return (
    <PageContainer fluid>
      <TableProvider tableSort={tableSort}>
        <Helmet>
          <title>E-postmeddelanden</title>
        </Helmet>
        <PageHeader>E-postmeddelanden</PageHeader>
        <ErrorAlert className="my-3" error={listQuery.error} />
        <Card>
          <Card.Header className="p-3">
            <TableFilterVirtual
              isLoading={listQuery.isLoading || listQuery.isRefetching}
              filterDefinitions={filterDefinitions}
              filterParams={filterParams}
              setFilterParams={setFilterParams}

              columnDefinitions={columnDefinitions}
              columnOrder={tableColumns}
              setColumnOrder={setTableColumns}

              onReload={listQuery.refetch}
              onReset={filterReset}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={listQuery.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnOrder={tableColumns}
                isInitialLoading={listQuery.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultTableColumns = [
  'id',
  'object',
  'subject',
  'view',
  'to_name',
  'created_by_user_id',
  'created_at',
  'actions',
];

const columnSortable = [
  'id',
  'view',
  'to_name',
  'created_by_user_id',
  'created_at',
];

const views = [
  'email.common',
  'email.customerSurvey',
  'email.companyEventCustomerBatch',
  'email.companyEventUserBatch',
  'email.notifyNewReport',
  'email.resetPassword',
];

const viewOptionList = views.map(view => ({
  value: view,
  label: <EmailFormatters.EmailTemplate value={view} />,
}));

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.input(['user_id', 'Användar'], {
    extraProps: {placeholder: 'Oavsett användar-ID', className: 'flex-grow-1'},
  }),
  filterDefs.input(['customer_id', 'Kund'], {
    extraProps: {placeholder: 'Oavsett kund-ID'},
  }),
  filterDefs.input(['to_address', 'Mottagare'], {
    extraProps: {placeholder: 'Oavsett mottagande e-postadress'},
  }),
  filterDefs.input(['created_by_user_id', 'Skapare'], {
    extraProps: {placeholder: 'Oavsett skapar-ID'},
  }),
  filterDefs.input(['customer_survey_id', 'Kundundersökning'], {
    extraProps: {placeholder: 'Oavsett kundundersökning-ID'},
  }),
  filterDefs.input(['company_event_customer_batch_id', 'Händelsebatch'], {
    extraProps: {placeholder: 'Oavsett händelsebatch-ID'},
  }),
  filterDefs.select(['event_type', 'Mall'], viewOptionList, {
    extraProps: {placeholder: 'Oavsett e-postmall'},
  }),
];


const columnDefinitionFactory: ColumnDefinitionFactory<IEmail> = () => [
  columnDefs.cell(['id', 'ID'], props => (
    <EmailFormatters.EmailModalButton value={props.row} />
  )),
  columnDefs.cell(['object', 'Objekt'], props => (
    <EmailFormatters.EmailObject value={props.row} />
  )),
  columnDefs.simple(['subject', 'Ämne']),
  columnDefs.cell(['view', 'Mall'], props => (
    <EmailFormatters.EmailTemplate value={props.row.view} />
  )),
  columnDefs.cell(['to_name', 'Mottagare'], props => (
    <a href={`mailto:${props.row.to_address}`}>{props.row.to_address}</a>
  )),
  columnDefs.cell(['created_by_user_id', 'Skapare'], props => (
    props.row.creator ? <UserFormatters.UserAnchor value={props.row.creator} /> : <span>Auto</span>
  )),
  columnDefs.date(['created_at', 'Skickat']),
  columnDefs.cell(['user_id', 'Användare'], props => (
    props.row.user_id ? <UserFormatters.UserAnchor value={props.row.user_id} /> : <span>-</span>
  )),
  columnDefs.cell(['customer_id', 'Kund'], props => (
    props.row.customer_id ? <CustomerFormatters.CustomerAnchor value={props.row.customer_id} /> : <span>-</span>
  )),
  columnDefs.cell(['customer_survey_id', 'Kundundersökning'], props => (
    props.row.customer_survey_id ? <CustomerSurveyFormatters.CustomerSurveyAnchor value={props.row.customer_survey_id} /> : <span>-</span>
  )),
  columnDefs.cell(['company_event_customer_batch_id', 'Kundbatch'], props => (
    props.row.customer_survey_id ? <CompanyEventBatchFormatters.CompanyEventCustomerBatchAnchor value={props.row.company_event_customer_batch_id} /> : <span>{props.row.company_event_customer_batch_id}</span>
  )),
  columnDefs.cell(['company_event_user_batch_id', 'Användarbatch'], props => (
    props.row.customer_survey_id ? <CompanyEventBatchFormatters.CompanyEventCustomerBatchAnchor value={props.row.company_event_user_batch_id} /> : <span>{props.row.company_event_user_batch_id}</span>
  )),
  columnDefs.actions(),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
