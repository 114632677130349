import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use(
  function (config) {
    if (!config.headers) config.headers = {};
    config.headers['Accept'] = 'application/json';
    return config;
  },
);

export default instance;
