import React from 'react';
import { NavLink } from 'react-router-dom';
import RoleGuard from 'client/guards/RoleGuard';
import { NavDropdown, Nav } from 'react-bootstrap';
import HasCompanyMonitoringGuard from 'client/guards/HasCompanyMonitoringGuard';

export default function NavbarMainMenu () {
  return (
    <Nav className="me-auto">
      <Nav.Item>
        <Nav.Link as={NavLink} to="/" exact>
          Hem
        </Nav.Link>
      </Nav.Item>

      <RoleGuard role={['admin']} invert>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/customers">
            Kunder
          </Nav.Link>
        </Nav.Item>
      </RoleGuard>

      <RoleGuard role={['admin']}>
        <NavDropdown title="Kunder" menuVariant="dark">
          <NavDropdown.Item as={NavLink} to="/customers">
            Alla kunder
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/customer_survey">
            Undersökningar
          </NavDropdown.Item>
        </NavDropdown>
      </RoleGuard>

      <RoleGuard role={['admin', 'coordinator', 'team_leader']}>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/users">
            Användare
          </Nav.Link>
        </Nav.Item>
      </RoleGuard>

      <RoleGuard role={['admin']} invert>
        <Nav.Item>
          <Nav.Link as={NavLink} to="/companies" exact>
            Företag
          </Nav.Link>
        </Nav.Item>
      </RoleGuard>

      <RoleGuard role={['admin']}>
        <NavDropdown title="Företag" menuVariant="dark">
          <NavDropdown.Item as={NavLink} to="/companies">
            Alla företag
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/credit_report">
            Kreditupplysningar
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/annual_report">
            Årsredovisningar
          </NavDropdown.Item>
        </NavDropdown>
      </RoleGuard>

      <Nav.Item>
        <Nav.Link as={NavLink} to="/report_queue/ready_not_done">
          Rapportkö
        </Nav.Link>
      </Nav.Item>

      <RoleGuard role={['admin']} invert>
        <HasCompanyMonitoringGuard>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/monitoring">
              Mina bevakningar
            </Nav.Link>
          </Nav.Item>
        </HasCompanyMonitoringGuard>
      </RoleGuard>

      <RoleGuard role={['admin']}>
        <NavDropdown title="Bevakning" menuVariant="dark">
          <HasCompanyMonitoringGuard>
            <NavDropdown.Item as={NavLink} to="/monitoring">
              Mina bevakningar
            </NavDropdown.Item>
          </HasCompanyMonitoringGuard>
          <NavDropdown.Item as={NavLink} to="/company_monitored">
            Bevakade företag
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/company_monitored_update">
            Uppdateringar
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/company_event">
            Händelser
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/company_event_customer_batch">
            Kundbatcher
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/company_event_user_batch">
            Användarbatcher
          </NavDropdown.Item>
        </NavDropdown>
      </RoleGuard>

      <RoleGuard role="admin">
        <NavDropdown title="Övrigt" menuVariant="dark">
          <NavDropdown.Item as={NavLink} to="/emails">
            E-postmeddelanden
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/email_templates">
            E-postmallar
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/cs/imported">
            Creditsafe-importer
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/cs/event">
            Creditsafe-händelser
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/corporate_tax_rate">
            Bolagsskattesatser
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/branches">
            Branscher
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/sni">
            SNI-lista
          </NavDropdown.Item>
          <NavDropdown.Item as={NavLink} to="/settings">
            Systeminställningar
          </NavDropdown.Item>
        </NavDropdown>
      </RoleGuard>
    </Nav>
  );
}
