import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, Button, Table, Form } from 'react-bootstrap';
import axios from 'client/axios';
import InspectObjectModalButton from 'client/buttons/InspectObjectModalButton';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import RefreshButton from 'client/buttons/RefreshButton';
import classNames from 'classnames';
import DateFormat from 'client/components/DateFormat';
import ErrorAlert from 'client/components/ErrorAlert';
import { UserAnchor } from 'client/user/UserFormatters';
import { CustomerLogRowWithRelations, CustomerLogType } from 'client/customer/types';
import * as CustomerLogFormatters from 'client/customer/CustomerLogFormatters';
import { pickBy } from 'lodash';

interface CustomerLogFilter {
  type: '' | keyof CustomerLogType;
}

interface CustomerReportTableProps {
  customerId: string;
  className?: string;
}

const CustomerLogTable: React.FC<CustomerReportTableProps> = React.memo(function CustomerLogTable (props: CustomerReportTableProps) {
  const { className, customerId } = props;

  const [filter, setFilter] = useState<CustomerLogFilter>({
    type: '',
  });

  const query = useQuery<CustomerLogRowWithRelations[], Error>({
    queryKey: ['CustomerLogTable', {customerId, filter}],
    queryFn: () => axios.get(`/api/customers/${customerId}/log`, {
      params: pickBy(filter, v => v),
    }).then(r => r.data),
  });

  const onChangeFilter = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const name = ev.target.name as keyof CustomerLogFilter;
    setFilter({
      ...filter,
      [name]: ev.target.value as any,
    });
  };

  const onClickReset = () => {
    setFilter({type: ''});
  };

  const list = query.data ?? [];

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h6" className="mb-0 p-0 d-flex justify-content-between align-items-center">
          Kundlogg
          <div>
            <RefreshButton className="px-2" onClick={query.refetch} disabled={query.isLoading || query.isRefetching} />
          </div>
        </Card.Title>
      </Card.Header>
      {query.error && (
        <Card.Body className="border border-bottom-0">
          <ErrorAlert className="mb-0" error={query.error} />
        </Card.Body>
      )}
      <Card.Body className="border border-bottom-0 p-2">
        <Form className="d-flex gap-2">
          <Form.Group>
            <Form.Select
              name="type"
              size="sm"
              value={String(filter.type)}
              onChange={onChangeFilter}
            >
              <option value="">Oavsett loggtyp</option>
              {[
                'login',
                'logout',
                'login_failed',
                'report_downloaded',
                'created',
              ].map(value => (
                <option key={value} value={value}>
                  {CustomerLogFormatters.logTypeToDescription(value as CustomerLogType)}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Button variant="outline-primary" size="sm" onClick={onClickReset}>
            Återställ
          </Button>
        </Form>
      </Card.Body>
      <TableSpinningOverlay isLoading={query.isLoading || query.isRefetching}>
        <div className="table-responsive">
          <Table className="mb-0 border align-middle">
            <thead>
              <tr>
                <th>Datum</th>
                <th>Beskrivning</th>
                <th>Utförd av</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && !list.length && (
                <tr>
                  <td colSpan={4} className="p-2">Det finns ingenting här</td>
                </tr>
              )}
              {list.map(item => (
                <tr key={item.id}>
                  <td>
                    <DateFormat format="YYYY-MM-DD HH:mm:ss" value={item.date} />
                  </td>
                  <td>
                    <CustomerLogFormatters.CustomerLogDescription value={item} />
                  </td>
                  <td>
                    {item.by_user && (
                      <UserAnchor value={item.by_user} />
                    )}
                  </td>
                  <td>
                    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
                      <InspectObjectModalButton object={item} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
    </Card>
  );
});
export default CustomerLogTable;
