import React, { useCallback, useState, useEffect, FormEventHandler } from 'react';
import CompanySearch, { useCompanySearchManager } from 'client/components/CompanySearch';
import PageContainer from 'client/components/PageContainer';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import {
  Card,
  Form,
} from 'react-bootstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import LoadingButton from 'client/buttons/LoadingButton';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useLocalStorage } from 'client/hooks/useStorage';
import IndustryReportForm from 'client/industryReport/IndustryReportForm';
import {
  RecallPreviousFormCard,
  SwitchToGroupMotherCompanyAlert,
} from 'client/components/ReportFormHelpers';
import {
  IIndustryReportFormData,
  IIndustryReportsGetCompanyInfoData,
} from 'client/industryReport/types';
import { ICreateReportQueueData } from 'client/reportQueue/types';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import PageHeader from 'client/components/PageHeader';

interface PageState {
  orgNumber: string;
  form: IIndustryReportFormData;
}

type IndustryReportCreateVars = PageState;

interface IndustryReportCreatePageParams {
  orgNumber?: string;
}

export default function IndustryReportCreatePage () {
  const history = useHistory();
  const { orgNumber } = useParams<IndustryReportCreatePageParams>();
  const [pageState, setPageState] = useLocalStorage<PageState | null>('IndustryReportCreatePageSavedData', null);
  const [showRecallPrevious, setShowRecallPrevious] = useState(() => (orgNumber && pageState?.form && orgNumber === pageState.form.report_org_number) || (!orgNumber && Boolean(pageState?.form)));
  const companySearchManager = useCompanySearchManager();

  const companyInfoQuery = useQuery<IIndustryReportsGetCompanyInfoData, Error>({
    enabled: Boolean(orgNumber),
    queryKey: [`/api/industry_reports/${orgNumber}/company_info`],
  });

  const createIndustryReportMutation = useMutation<ICreateReportQueueData, Error, IndustryReportCreateVars>({
    mutationKey: ['CreateIndustryReport'],
    mutationFn: vars => axios.post(`/api/industry_reports/${vars.orgNumber}`, vars.form).then(result => result.data),
    onSuccess: result => {
      setPageState(null);
      requestCallbacks.onSuccess('Branschrapporten har skapats');
      history.push({pathname: `/industry_reports/${result.report_queue.id}/properties`});
    },
  });

  useEffect(() => {
    if (!companyInfoQuery.isSuccess) return;

    const orgNumberManuallyChanged = pageState && companyInfoQuery.data.company.org_number !== pageState.form.report_org_number;
    const populateEmptyForm = !showRecallPrevious && !pageState;
    if (orgNumberManuallyChanged || populateEmptyForm) {
      setPageState({orgNumber: orgNumber!, form: companyInfoQuery.data.form});
    }
  }, [companyInfoQuery.data, companyInfoQuery.isSuccess, showRecallPrevious, pageState]);

  const onSwapOrgNumber = useCallback((orgNumber: string) => {
    history.push({pathname: `/industry_reports/create/${orgNumber}`});
    companySearchManager.setValue(orgNumber);
  }, [history.push]);

  const onSubmitCreateForm = () => {
    if (pageState === null || !orgNumber) return;
    createIndustryReportMutation.mutate({orgNumber, form: pageState.form});
  };

  const onSubmitOrgNumberSearchForm: FormEventHandler<HTMLFormElement> = useCallback((ev) => {
    ev.preventDefault();
    const swapToOrgNumber = new FormData(ev.target as HTMLFormElement).get('org_number');
    if (swapToOrgNumber !== null) {
      setShowRecallPrevious(false);
      setPageState(null);
      onSwapOrgNumber(swapToOrgNumber as string);
    }
  }, [setShowRecallPrevious, setPageState, onSwapOrgNumber]);

  const onClickRecall = () => {
    setShowRecallPrevious(false);
    onSwapOrgNumber(pageState!.form.report_org_number);
  };

  const onSetForm: React.Dispatch<React.SetStateAction<PageState['form']>> = useCallback((setState) => {
    setPageState((state: PageState | null) => ({
      orgNumber: orgNumber!,
      form: (typeof setState === 'function' ? setState(state!.form) : setState),
    }));
  }, [setPageState, orgNumber]);

  const data = companyInfoQuery.data;

  return (
    <PageContainer fluid="md">
      <Helmet>
        <title>Ny köad branschrapport</title>
      </Helmet>
      <PageHeader>Ny köad branschrapport</PageHeader>
      <Card className="mb-4">
        <Form onSubmit={onSubmitOrgNumberSearchForm}>
          <Card.Body>
            <div className="d-flex gap-2 flex-wrap">
              <CompanySearch
                className="w-auto flex-grow-1"
                defaultInputValue={orgNumber}
                {...companySearchManager.props}
              />
              <LoadingButton
                type="submit"
                isLoading={companyInfoQuery.isLoading}
                disabled={!companySearchManager.props.isValid}
              >
                Hämta
              </LoadingButton>
            </div>
            <ErrorAlert className="mt-3 mb-0" error={companyInfoQuery.error} />
          </Card.Body>
        </Form>
      </Card>
      {showRecallPrevious && pageState && (
        <RecallPreviousFormCard
          orgNumber={pageState.form.report_org_number}
          onClickRecall={onClickRecall}
        />
      )}
      {!showRecallPrevious && companyInfoQuery.isSuccess && data?.groupStructureGroupMotherCompany && (
        <SwitchToGroupMotherCompanyAlert
          currentOrgNumber={data.company.org_number}
          currentCompanyName={data.company.company_name || ''}
          onSwapOrgNumber={onSwapOrgNumber}
          groupMotherCompany={data.groupStructureGroupMotherCompany}
        />
      )}
      <ErrorAlert className="mt-3 mb-0" error={companyInfoQuery.error} />
      {!showRecallPrevious && companyInfoQuery.isSuccess && pageState && (
        <IndustryReportForm
          data={companyInfoQuery.data}
          form={pageState.form}
          setForm={onSetForm}
          onSubmitForm={onSubmitCreateForm}
          submitError={createIndustryReportMutation.error}
          submitIsLoading={createIndustryReportMutation.isPending}
        />
      )}
    </PageContainer>
  );
}
