import React, { FC, Key, useState, useEffect } from 'react';
import SelectCustomerModal from 'client/modals/SelectCustomerModal';
import { useMutation } from '@tanstack/react-query';
import { ICsBasicExtended } from 'client/cs/types';
import axios from 'client/axios';
import { Link } from 'react-router-dom';
import { CustomerRow } from 'client/customer/types';
import { Form,  Col, Row, FormControlProps, Spinner } from 'react-bootstrap';
import ErrorAlert from 'client/components/ErrorAlert';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import { Edit, ExternalLink } from 'react-feather';
import IdProvider from 'client/components/IdProvider';

export interface CustomerFormData {
  name: string;
  id: string;
  email: string;
  contact_person: string;
}

export function getEmptyFormData () {
  return {
    name: '',
    id: '',
    email: '',
    contact_person: '',
  };
}

interface IProps {
  name: string;
  customerId: Key | null;
  onChangeCustomerId: (name: string, newValue: Key | null) => void;
  setCustomerData: React.Dispatch<React.SetStateAction<Partial<CustomerRow> | null>>;
  companyData?: ICsBasicExtended;
  customerData?: Partial<CustomerRow> | null;
  emailRequired: boolean;
  required: boolean;
}

const CustomerSelect: FC<IProps> = (props: IProps) => {
  const {
    name,
    customerId,
    onChangeCustomerId,
    customerData,
    setCustomerData,
    companyData,
    required,
    emailRequired,
  } = props;

  const [formOriginal, setFormOriginal] = useState<CustomerFormData>(getEmptyFormData());
  const [formData, setFormData] = useState<CustomerFormData>(getEmptyFormData());

  useEffect(() => {
    let formData;
    if (customerId && customerData) {
      formData = customerToFormData(customerData);
    } else {
      formData = getEmptyFormData();
    }
    setFormData(formData);
    setFormOriginal(formData);
  }, [customerId]);

  const onChangeInput = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = ev.target;
    setFormData({...formData, [name]: value});
  };

  const onBlurInput = (ev: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = ev.currentTarget;
    // ffs typescript
    const formOriginalAny = formOriginal as unknown as {[key: string]: string};
    const currentValue = formOriginalAny[name];
    if (currentValue === value) return Promise.resolve(); // don't update unless value changed
    const update = {[name]: value};
    return updateCustomerMutation.mutateAsync(update);
  };

  const onSelectCustomer = (customer: CustomerRow) => {
    onChangeCustomerId(name, customer.id);
    setCustomerData(customer);
  };

  const updateCustomerMutation = useMutation<CustomerRow, Error, Partial<CustomerFormData>>({
    mutationKey: ['CustomerSelectUpdateCustomer'],
    mutationFn: vars => axios.patch(`/api/customers/${customerId}`, vars).then(r => r.data),
    onSuccess: customer => {
      const formData = customerToFormData(customer);
      setCustomerData(customer);
      setFormOriginal(formData);
    },
  });

  const initialValuesForNewCustomer = {
    name: companyData?.company_name || '',
    id: companyData?.org_number || '',
    contact_person: '',
    email: '',
  };

  const onChangeCustomerIdSelect = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    const value = ev.target.value || null;
    onChangeCustomerId(name, value);
    if (value === null) setCustomerData(null);
  };

  return (
    <div>
      <ErrorAlert error={updateCustomerMutation.error} />
      <Form.Group className="mb-3">
        <Form.Label>Kund-ID</Form.Label>
        <div className="d-flex flex-wrap gap-2">
          <Form.Select
            className="w-auto flex-grow-1"
            value={customerId ?? ''}
            required={required}
            isInvalid={required ? !customerId : false}
            name="customer_id"
            onChange={onChangeCustomerIdSelect}
          >
            <option value="">Ingen kund angiven</option>
            {customerId && <option value={customerId}>{customerId}</option>}
          </Form.Select>
          {customerId && (
            <Link
              className="btn btn-outline-primary btn-sm d-flex gap-1 align-items-center"
              to={`/customer/${customerId}/form`}
            >
              <ExternalLink size={18} />
              Öppna kundkort
            </Link>
          )}
          <ModalOpeningButton
            Modal={SelectCustomerModal as React.FC}
            modalProps={{
              initialCreateFormValues: initialValuesForNewCustomer,
              onSelectCustomer,
            }}
            className="d-flex gap-1 align-items-center"
            size="sm"
            variant="outline-primary"
          >
            <Edit size={18} />
            {customerId ? 'Välj en annan kund' : 'Välj en kund'}
          </ModalOpeningButton>
          <Form.Control.Feedback type="invalid">En kund måste anges</Form.Control.Feedback>
        </div>
      </Form.Group>
      {customerId && (
        <CustomerFormFields
          formData={formData}
          onChangeInput={onChangeInput}
          onBlurInput={onBlurInput}
          emailRequired={emailRequired}
        />
      )}
    </div>
  );
};

export default CustomerSelect;

function customerToFormData (customer: Partial<CustomerRow>): CustomerFormData {
  const { id, name, email, contact_person } = customer;
  return {
    id: id || '',
    name: name || '',
    email: email || '',
    contact_person: contact_person || '',
  };
}

interface CustomerFormFieldsProps {
  formData: CustomerFormData;
  onChangeInput: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  onBlurInput?: (ev: React.FocusEvent<HTMLInputElement>) => Promise<any>;
  vertical?: boolean;
  create?: boolean;
  emailRequired: boolean;
}

// TODO scrap this component
function CustomerFormFields (props: CustomerFormFieldsProps) {
  const { emailRequired, formData, create, onBlurInput, onChangeInput, vertical } = props;
  const colProps = vertical ? {sm: 12} : {lg: 4, md: 6};
  return (
    <Row className="mb-3">
      {create && (
        <Col {...colProps}>
          <CustomerFormFieldInput
            label="ID/Organisationsnummer"
            name="id"
            placeholder="Ange ett ID/organisationsnummer"
            value={formData.id}
            onChange={onChangeInput}
            onBlur={onBlurInput}
          />
        </Col>
      )}
      <Col {...colProps}>
        <CustomerFormFieldInput
          label="Företagsnamn"
          name="name"
          placeholder="Ange ett företagsnamn"
          value={formData.name}
          onChange={onChangeInput}
          onBlur={onBlurInput}
        />
      </Col>
      <Col {...colProps}>
        <CustomerFormFieldInput
          label="E-postadress"
          type="email"
          name="email"
          placeholder="Ange en e-postadress"
          value={formData.email}
          onChange={onChangeInput}
          onBlur={onBlurInput}
          required={emailRequired}
        />
      </Col>
      <Col {...colProps}>
        <CustomerFormFieldInput
          label="Kontaktperson"
          name="contact_person"
          placeholder="Ange en kontaktperson"
          value={formData.contact_person}
          onChange={onChangeInput}
          onBlur={onBlurInput}
        />
      </Col>
    </Row>
  );
}

interface CustomerFormFieldInputProps extends FormControlProps {
  label: string;
  name: string;
  required?: boolean;
  value: any;
  onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => Promise<any>;
}
function CustomerFormFieldInput (props: CustomerFormFieldInputProps) {
  const { label, value, required, onBlur:onBlurOuter, ...restOfProps } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onBlur = (ev: React.FocusEvent<HTMLInputElement>) => {
    if (!onBlurOuter) return;
    setIsLoading(true);
    onBlurOuter(ev).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <IdProvider>
      {id => (
        <Form.Group>
          <Form.Label htmlFor={id} className="d-flex align-items-center">
            <span>{label}</span>
            {isLoading && <Spinner className="ms-2" size="sm" />}
          </Form.Label>
          <Form.Control
            {...restOfProps}
            required={required}
            value={value}
            id={id}
            onBlur={onBlur}
            isInvalid={required && !value}
          />
          <Form.Control.Feedback type="invalid">
            Ange ett giltigt värde
          </Form.Control.Feedback>
        </Form.Group>
      )}
    </IdProvider>
  );
}
