import React from 'react';
import PageContainer from 'client/components/PageContainer';
import { useMutation } from '@tanstack/react-query';
import { Formik, Form as FormikForm, FormikHelpers } from 'formik';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useHistory } from 'react-router-dom';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import { Card } from 'react-bootstrap';
import { CustomerRow } from 'client/customer/types';
import CustomerForm, { CreateCustomerFormFields } from 'client/customer/CustomerForm';
import { Helmet } from 'react-helmet';
import SaveButton from 'client/buttons/SaveButton';
import { maybeAxiosErrorToErrorMap } from 'client/utils/errors';
import PageHeader from 'client/components/PageHeader';

export default function CustomerCreatePage () {
  const history = useHistory();

  const createCustomerMutation = useMutation<CustomerRow, Error, CreateCustomerFormFields>({
    mutationFn: vars => axios.post('/api/customers', vars).then(r => r.data),
    onSuccess: user => {
      requestCallbacks.onSuccess('Användaren har skapats');
      history.push(`/customer/${user.id}/form`);
    },
  });

  const onSubmit = (form: CreateCustomerFormFields, helpers: FormikHelpers<CreateCustomerFormFields>) => {
    createCustomerMutation.mutateAsync(form).catch(err => {
      helpers.setSubmitting(false);
      const errorMap = maybeAxiosErrorToErrorMap(err);
      if (errorMap) {
        helpers.setErrors(errorMap);
        return;
      }
      throw err;
    });
  };

  const initialValues = {
    id: '',
    name: '',
    email: '',
    contact_person: '',
    language: 'sv',
  };

  return (
    <PageContainer fluid="sm">
      <Helmet>
        <title>Ny kund</title>
      </Helmet>
      <PageHeader breadcrumbs={[{title: 'Kunder', url: '/customers'}, {title: 'Ny kund'}]}>
        Ny kund
      </PageHeader>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {formikBag => (
          <FormikForm>
            <Card>
              <Card.Body className="pb-1">
                <CustomerForm formikBag={formikBag} isCreateForm hidePasswordForm />
                <ErrorAlert error={createCustomerMutation.error} />
              </Card.Body>
              <Card.Footer className="d-flex justify-content-end gap-2 p-2">
                <SaveButton
                  type="submit"
                  isLoading={createCustomerMutation.isPending}
                  disabled={!formikBag.isValid || formikBag.isSubmitting || !Object.keys(formikBag.touched).length}
                />
              </Card.Footer>
            </Card>
          </FormikForm>
        )}
      </Formik>
    </PageContainer>
  );
}
