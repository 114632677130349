import { Button as BsButton, ButtonProps, ModalProps } from 'react-bootstrap';
import { useModalState } from 'client/hooks/useModalState';

export interface ModalOpeningButtonProps<T extends React.ComponentType<ModalProps>> extends ButtonProps {
  Modal: React.ComponentType<any>;
  modalProps: React.ComponentProps<T>;
  buttonAs?: React.ElementType<any> | undefined;
}

export default function ModalOpeningButton<T extends React.ComponentType> (props: ModalOpeningButtonProps<T>) {
  const {
    children,
    Modal,
    modalProps = {},
    buttonAs: Button = BsButton,
    ...buttonProps
  } = props;

  const {
    show,
    mounted,
    onShow,
    onExited,
    onHide,
  } = useModalState();

  const onClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    onShow();
  };

  return (
    <>
      <Button {...buttonProps} onClick={onClick}>
        {children}
      </Button>
      {mounted && (
        <Modal
          {...modalProps}
          show={show}
          onHide={onHide}
          onExited={onExited}
        />
      )}
    </>
  );
}
