import React from 'react';
import PageContainer from 'client/components/PageContainer';
import {
  IndustryReportTable,
  ValuationReportTable,
} from 'client/asCustomer/ReportTables';
import { Helmet } from 'react-helmet';

export default function DashboardPage () {
  return (
    <PageContainer fluid="lg" className="p-4">
      <Helmet>
        <title>Rapporter</title>
      </Helmet>
      <h2>Välkommen till Calculates kundportal!</h2>
      <p className="mb-0">Här nedan ser ni era beställda rapporter.</p>
      <ValuationReportTable className="mt-4" />
      <IndustryReportTable className="mt-4" />
    </PageContainer>
  );
}
