import React from 'react';
import {Container, ContainerProps} from 'react-bootstrap';

function PageContainer (props: ContainerProps) {
  const { children, className = 'p-4 pt-2', ...restOfProps } = props;
  return (
    <Container {...restOfProps} className={className}>
      {children}
    </Container>
  );
}

export default React.memo(PageContainer);
