import axios from 'client/axios';

export function urlPathWithQuery (pathname: string, query: Record<string, string>): string {
  const searchParams = new URLSearchParams(query);
  return `${pathname}?${searchParams.toString()}`;
}

interface DownloadUrlAsFileOptions {
  fileName?: string;
}

export async function downloadUrlAsFile (url: string, options: DownloadUrlAsFileOptions = {}) {
  const response = await axios.get(url, {
    responseType: 'blob',
    headers: { 'Access-Control-Allow-Origin': '*' },
  });

  const data = response?.data;
  if (!data) {
    const msg = `No file data received from URL "${url}"`;
    throw new Error(msg);
  }

  const blob = new Blob([data], {
    type: response.headers['content-type'],
  });
  const link = document.createElement('a');

  const fileName = options.fileName || contentDispositionToFileName(response.headers['content-disposition']);

  link.href = URL.createObjectURL(blob);
  if (fileName) link.download = fileName;
  link.click();
}

function contentDispositionToFileName (value: string | null): string | null {
  if (!value) return null;
  const matches = value.match(/filename=(.+$)/);
  if (!matches) return null;
  return matches[1] || null;
}
