import { useState, useEffect, useRef, ReactNode, useCallback } from 'react';
import {
  Modal,
  ModalProps,
  Table,
  Badge,
  Button,
  Spinner,
  OverlayTrigger,
  Tooltip,
} from 'react-bootstrap';
import axios from 'client/axios';
import { DownloadCloud, HelpCircle } from 'react-feather';

type DownloadableFile = {
  id: number | string;
  url: string;
  fileName: string;
  render: () => ReactNode;
}

type MissingFile = {
  id: number | string;
  render: () => ReactNode;
}

type File = MissingFile | DownloadableFile;

type Status = 'done' | 'waiting' | 'missing' | 'ongoing' | 'error';

interface FileState {
  status: Status;
  message?: string;
}

type DownloadFilesModalState = Record<File['id'], FileState>;

export interface DownloadFilesModalProps extends ModalProps {
  files?: File[];
}

export default function DownloadFilesModal (props: DownloadFilesModalProps) {
  const { files = [], mounted, ...restOfProps } = props;

  const [state, setState] = useState<DownloadFilesModalState>(files.reduce<DownloadFilesModalState>((map, file) => {
    map[file.id] = {status: isDownloadable(file) ? 'waiting' : 'missing'};
    return map;
  }, {}));

  const isRunningRef = useRef(false);

  const setFileState = (id: File['id'], update: Partial<FileState>) => {
    setState(prevState => ({
      ...prevState,
      [id]: {...(prevState[id] || {}), ...update},
    }));
  };

  const downloadFile = async (file: DownloadableFile) => {
    const { url, fileName, id } = file;

    try {
      setFileState(id, {status: 'ongoing', message: ''});

      const response = await axios.get(url, {
        responseType: 'blob',
        headers: { 'Access-Control-Allow-Origin': '*' },
      });
      const data = response?.data;
      if (!data) {
        throw new Error('no data');
      }

      const blob = new Blob([data], { type: data.type });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      setFileState(id, {status: 'done', message: ''});
    } catch (err) {
      if (!(err instanceof Error)) return;
      setFileState(id, {status: 'error', message: (err as Error).message});
    }
  };

  const run = async () => {
    isRunningRef.current = true;
    for (const file of files) {
      if (!isRunningRef.current) break;
      if (isDownloadable(file)) {
        await downloadFile(file);
      }
    }
  };

  const stop = () => {
    isRunningRef.current = false;
  };

  useEffect(() => {
    if (mounted && files.length > 0) {
      run();
    }
    if (!mounted) {
      stop();
      setState({});
    }
  }, [mounted]);

  if (!mounted) {
    return null;
  }

  return (
    <Modal
      {...restOfProps}
      centered
      scrollable
      size="lg"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Filnedladdning
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table className="mb-1 align-middle" bordered size="sm" striped>
          <thead>
            <tr>
              <th>Objekt</th>
              <th>Filnamn</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {files.map(file => (
              <FileRow
                key={file.id}
                file={file}
                state={state[file.id]}
                onDownload={downloadFile}
              />
            ))}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
}

interface FileRowProps {
  file: File;
  state: FileState;
  onDownload: (file: DownloadableFile) => void;
}

function FileRow (props: FileRowProps) {
  const { file, state, onDownload } = props;
  const { id, render } = file;

  const onClickDownload = useCallback(() => {
    if (!isDownloadable(file)) return;
    onDownload(file);
  }, [onDownload, file]);

  return (
    <tr key={id}>
      <td>{render()}</td>
      <td>
        {isDownloadable(file) ? (
          <a href={file.url} target="_blank" rel="noreferrer">{file.fileName}</a>
        ) : '-'}
      </td>
      <td>
        <div className="d-flex gap-1 align-items-center">
          <StatusFormatter value={state.status} />
          {state.message && (
            <OverlayTrigger placement="bottom" overlay={<Tooltip style={{position: 'fixed'}}>{state.message}</Tooltip>}>
              <HelpCircle size={16} className="text-danger" />
            </OverlayTrigger>
          )}
        </div>
      </td>
      <td className="text-end">
        {isDownloadable(file) && (
          <Button
            onClick={onClickDownload}
            size="sm"
            variant="outline-primary"
            className="py-0 px-1"
            disabled={state.status === 'waiting' || state.status === 'ongoing'}
          >
            <DownloadCloud size={16} />
            {' '}Ladda hem
          </Button>
        )}
      </td>
    </tr>
  );
}

function StatusFormatter (props: { value: Status }) {
  const { value } = props;
  switch (value) {
    default:
      return <Badge className="px-2 text-uppercase">{value}</Badge>;
    case 'done':
      return <Badge className="px-2 text-uppercase" bg="success">Klar</Badge>;
    case 'missing':
      return <Badge className="px-2 text-uppercase" bg="warning">Fil saknas</Badge>;
    case 'error':
      return <Badge className="px-2 text-uppercase" bg="danger">Fel</Badge>;
    case 'waiting':
      return (
        <Badge className="px-2 text-uppercase d-inline-flex align-items-center gap-2" bg="light text-dark">
          <Spinner size="sm" />
          Väntar
        </Badge>
    );
    case 'ongoing':
      return (
        <Badge className="px-2 text-uppercase d-inline-flex align-items-center gap-2" bg="info">
          <Spinner size="sm" />
          Laddar ner
        </Badge>
    );
  }
}

function isDownloadable (file: File): file is DownloadableFile {
  return 'url' in file && 'fileName' in file;
}
