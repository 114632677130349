import React, { useMemo, useState, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Helmet } from 'react-helmet';
import { Card, Form, Modal } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PageHeader from 'client/components/PageHeader';
import ErrorAlert from 'client/components/ErrorAlert';
import { ColumnDefinition, FilterDefinition } from 'client/table/types';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import * as CustomerFormatters from 'client/customer/CustomerFormatters';
import { CompanyEventCustomerBatchTableRowWithRelations } from 'client/companyEventBatch/types';
import { TableSpinningOverlay, TableCardFooterWithPagination  } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import TableFilterVirtual from 'client/table/TableFilterVirtual';
import { TableProvider } from 'client/contexts/TableContext';
import SendCompanyEventBatchEmailButtonProps from 'client/email/SendCompanyEventBatchEmailButton';
import { Mail } from 'react-feather';
import ModalOpeningButton from 'client/buttons/ModalOpeningButton';
import BlockSpinner from 'client/spinners/BlockSpinner';

export interface CompanyEventCustomerBatchTableFilterParams {
  customer_id?: string;
  created_at_from?: string;
  created_at_to?: string;
}

interface ListData {
  rows: CompanyEventCustomerBatchTableRowWithRelations[];
  total_rows: number;
}

const defaultFilterParams = {};

const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
  ...defaultFilterParams,
};

export default function CompanyEventCustomerBatchTablePage () {
  const {
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
    setFilterParams,
    params,
  } = useTableState({
    defaultState,
  });

  const query = useQuery<ListData, Error>({
    queryKey: ['/api/company_event_customer_batch/list', params],
    placeholderData: keepPreviousData,
  });

  const rows = query.data?.rows || [];

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const allIds = useMemo(() => rows.map(item => item.id), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(query.data?.total_rows ?? 0);
  }, [query.data?.total_rows]);

  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);

  const [columnOrder, setColumnOrder] = useState(() => columnDefinitions.filter(c => c.show).map(c => c.id));

  return (
    <PageContainer fluid>
      <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
        <Helmet>
          <title>Kundbatcher</title>
        </Helmet>
        <PageHeader className="mt-4 mb-2">Kundbatcher</PageHeader>
        <p className="mb-4">
          Denna tabell visar uppdateringsbatcher som hör till kunder som använder bevakningsfunktionen. En uppdateringsbatch är en lista med företagshändelser på kundens bevakade bolag enligt kundens önskemål gällande vad som bevakas och med vilket intervall de vill bli notifierade om förändringar.
        </p>
        <ErrorAlert className="my-3" error={query.error} />
        <Card>
          <Card.Header className="p-3">
            <TableFilterVirtual
              isLoading={query.isLoading || query.isRefetching}
              filterDefinitions={filterDefinitions}
              filterParams={filterParams}
              setFilterParams={setFilterParams}

              columnDefinitions={columnDefinitions}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}

              onReload={query.refetch}
              onReset={filterReset}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={query.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnOrder={columnOrder}
                isInitialLoading={query.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={tablePagination?.totalCountOfRows || rows.length}
          />
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultTableColumns = [
  'select',
  'customer_id',
  'events',
  'emailed_at',
  'created_at',
  'actions',
];

const columnSortable = [
  'emailed_at',
  'created_at',
  'updated_at',
];

type FilterDefinitionFactory = () => FilterDefinition[];

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.input(['id', 'ID']),
  filterDefs.date(['created_at_from', 'Från']),
  filterDefs.date(['created_at_to', 'Till']),
];

type ColumnDefinitionFactory = () => ColumnDefinition<CompanyEventCustomerBatchTableRowWithRelations>[];

const columnDefinitionFactory: ColumnDefinitionFactory = () => [
  columnDefs.select(),
  columnDefs.cell(['customer_id', 'Kund'], props => (
    <CustomerFormatters.CustomerAnchor value={props.row.customer} />
  )),
  columnDefs.cell(['events', 'Händelser'], props => (
    <span>{props.row.events.length}</span>
  )),
  columnDefs.date(['created_at', 'Skapad']),
  columnDefs.date(['emailed_at', 'Mailad']),
  columnDefs.date(['updated_at', 'Uppdaterad']),

  columnDefs.actions(props => (
    <span className="d-flex gap-1">
      <SendCompanyEventBatchEmailButtonProps
        size="sm"
        className="d-flex gap-1 align-items-center px-1 py-0"
        variant="outline-primary"
        companyEventBatchId={props.row.id}
      >
        <Mail size={14} />
        Skicka
      </SendCompanyEventBatchEmailButtonProps>
      <ModalOpeningButton
        variant="outline-primary"
        className="d-flex gap-1 align-items-center px-1 py-0"
        Modal={EmailRenderModal as React.FC}
        modalProps={{companyEventBatchId: props.row.id}}
        size="sm"
      >
        <Mail size={14} />
        Rendera
      </ModalOpeningButton>
    </span>
  )),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);

export interface EmailRenderModalProps {
  companyEventBatchId: string;
  show: boolean;
  onHide: () => void;
  onExited: () => void;
}

function EmailRenderModal (props: EmailRenderModalProps) {
  const {
    show,
    companyEventBatchId,
    onHide,
    onExited,
  } = props;

  const [emailTemplateView, setEmailTemplateName] = useState<string>('email.companyEventCustomerBatch');

  const query = useQuery<string, Error>({
    queryKey: [`/api/company_event_customer_batch/${companyEventBatchId}/email/render`, {emailTemplateView}],
  });

  const onSubmit: React.FormEventHandler = ev => {
    ev.preventDefault();
  };

  const onChange: React.ChangeEventHandler<HTMLSelectElement> = ev => {
    setEmailTemplateName(ev.currentTarget.value);
  };

  return (
    <Modal show={show} onHide={onHide} onExited={onExited} size="lg">
      <Modal.Header>
        <Modal.Title>Händelsebatchmail</Modal.Title>
      </Modal.Header>
      <Modal.Body className="border-bottom">
        <Form onSubmit={onSubmit} className="d-flex gap-2 flex-wrap align-items-center">
          <Form.Group>
            <Form.Select
              name="emailTemplateView"
              value={emailTemplateView}
              onChange={onChange}
              required
            >
              <option value="email.companyEventCustomerBatch">email.companyEventCustomerBatch</option>
              <option value="email.companyEventCustomerBatchOrgNumber">email.companyEventCustomerBatchOrgNumber</option>
            </Form.Select>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Body className="m-2">
        <ErrorAlert error={query.error} />
        <BlockSpinner isLoading={query.isLoading}  />
        {query.isSuccess && (
          <div dangerouslySetInnerHTML={{__html: query.data}} />
        )}
      </Modal.Body>
    </Modal>
  );
}
