import React from 'react';
import { CompanyEventRow, CompanyEventTimelineBatchRow } from 'client/companyEvent/types';

interface CompanyEventValueChangeProps {
  value: Pick<CompanyEventRow, 'fmt_change'>;
}

export function CompanyEventValueChange (props: CompanyEventValueChangeProps) {
  const { value } = props;
  const { fmt_change } = value;
  return <>{fmt_change}</>;
}


interface CompanyEventTextChangeProps {
  value: Pick<CompanyEventRow, 'fmt_change'>;
}

export function CompanyEventTextChange (props: CompanyEventTextChangeProps) {
  const { value } = props;
  const { fmt_change } = value;
  return <>{fmt_change}</>;
}

interface CompanyEventChangeProps {
  value: Pick<CompanyEventRow, 'fmt_change'>;
}

export function CompanyEventChange (props: CompanyEventChangeProps) {
  const { value } = props;
  const { fmt_change } = value;
  return <>{fmt_change}</>;
}

interface CompanyEventDiffProps {
  value: Pick<CompanyEventRow, 'fmt_diff'>;
}

export function CompanyEventDiff (props: CompanyEventDiffProps) {
  const { value } = props;
  const { fmt_diff } = value;
  return <>{fmt_diff}</>;
}

interface CompanyEventTextProps {
  value: CompanyEventRow['text_curr'] | CompanyEventRow['text_prev'] ;
}

export function CompanyEventText (props: CompanyEventTextProps) {
  const { value } = props;
  if (value === null) return null;
  return (
    <span>"{value}"</span>
  );
}

interface CompanyEventCurrProps {
  value: Pick<CompanyEventRow, 'fmt_curr'>;
}

export function CompanyEventCurr (props: CompanyEventCurrProps) {
  const { value } = props;
  const { fmt_curr } = value;
  return <>{fmt_curr}</>;
}

interface CompanyEventPrevProps {
  value: Pick<CompanyEventRow, 'fmt_prev'>;
}

export function CompanyEventPrev (props: CompanyEventPrevProps) {
  const { value } = props;
  const { fmt_prev } = value;
  return <>{fmt_prev}</>;
}

interface CompanyEventValueProps {
  value: CompanyEventRow['fmt_prev'] | CompanyEventRow['fmt_curr'] ;
}

export function CompanyEventValue (props: CompanyEventValueProps) {
  const { value } = props;
  return <>{value}</>;
}

interface CompanyEventTypeProps {
  value: CompanyEventRow['fmt_event_type'];
}

export function CompanyEventType (props: CompanyEventTypeProps) {
  const { value } = props;
  return <>{value}</>;
}

interface CompanyEventTimelineBatchStartProps {
  value: Pick<CompanyEventTimelineBatchRow, 'fmt_start'>;
}

export function CompanyEventTimelineBatchStart (props: CompanyEventTimelineBatchStartProps) {
  const { value } = props;
  const { fmt_start } = value;
  return <>{fmt_start}</>;
}

interface CompanyEventTimelineBatchEndProps {
  value: Pick<CompanyEventTimelineBatchRow, 'fmt_end'>;
}

export function CompanyEventTimelineBatchEnd (props: CompanyEventTimelineBatchEndProps) {
  const { value } = props;
  const { fmt_end } = value;
  return <>{fmt_end}</>;
}
