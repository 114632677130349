import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar as BsNavbar, Container } from 'react-bootstrap';
import useAuth from 'client/hooks/useAuth';
import LogoSvg from 'client/assets/images/Calculate_White.svg';
import NavbarMainMenu from 'client/asCustomer/navbar/NavbarMainMenu';
import NavbarAccount from 'client/asCustomer/navbar/NavbarAccount';
import NavbarQuickSearch from 'client/asCustomer/navbar/NavbarQuickSearch';

export default function Navbar () {
  const auth = useAuth();
  if (!auth.isAuthenticated) {
    return null;
  }

  return (
    <BsNavbar bg="primary" variant="dark" expand="md" collapseOnSelect>
      <Container fluid>

        <BsNavbar.Brand as={NavLink} to="/" className="d-flex align-items-center">
          <img
            src={LogoSvg}
            width="106"
            height="23"
            className="d-inline-block align-top"
            alt="Calculate logo"
            style={{ position: 'relative', top: '-2px' }}
          />
        </BsNavbar.Brand>

        <BsNavbar.Toggle aria-controls="clc-responsive-navbar" />

        <BsNavbar.Collapse id="clc-responsive-navbar">
          <NavbarMainMenu />
          <NavbarQuickSearch className="me-4" />
          <NavbarAccount />
        </BsNavbar.Collapse>

      </Container>
    </BsNavbar>
  );
}
