import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, Table } from 'react-bootstrap';
import { TableSpinningOverlay  } from 'client/table/TableUtils';
import { IIndustryReport } from 'client/industryReport/types';
import { IValuationReport } from 'client/valuationReport/types';
import RefreshButton from 'client/buttons/RefreshButton';
import classNames from 'classnames';
import * as ReportFormatters from 'client/report/ReportFormatters';
import { AccountInterval } from 'client/accounts/AccountsFormatters';
import DateFormat from 'client/components/DateFormat';
import ErrorAlert from 'client/components/ErrorAlert';
import useAuth from 'client/hooks/useAuth';

interface ReportTableProps {
  className?: string;
}

export const IndustryReportTable: React.FC<ReportTableProps> = React.memo(function IndustryReportTable (props: ReportTableProps) {
  const { className } = props;

  const auth = useAuth();

  const query = useQuery<IIndustryReport[], Error>({
    enabled: Boolean(auth.customerId),
    queryKey: ['/api/as_customer/industry_reports'],
  });

  const list = query.data ?? [];

  const error = query.error;

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h5" className="mb-0 p-0 d-flex gap-2 flex-wrap justify-content-between align-items-center">
          Era branschrapporter
          <div>
            <RefreshButton className="px-2" onClick={query.refetch} disabled={query.isLoading || query.isRefetching} />
          </div>
        </Card.Title>
      </Card.Header>
      {error && (
        <Card.Body className="border border-bottom-0">
          <ErrorAlert className="mb-0" error={error} />
        </Card.Body>
      )}
      <TableSpinningOverlay isLoading={query.isLoading || query.isRefetching}>
        <div className="table-responsive">
          <Table className="mb-0 border align-middle">
            <thead>
              <tr>
                <th>Skapad</th>
                <th>Företag</th>
                <th>Bokslut</th>
                <th>Hämtad</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && !list.length && (
                <tr>
                  <td colSpan={11} className="p-2">
                    Ni har inga branschrapporter här
                  </td>
                </tr>
              )}
              {list.map(item => (
                <tr key={item.id}>
                  <td>
                    <DateFormat format="YYYY-MM-DD" value={item.created_at} />
                  </td>
                  <td>
                    {item.report_name} ({item.report_org_number})
                  </td>
                  <td>
                    <AccountInterval
                      from={item.account_date_from}
                      to={item.account_date_to}
                    />
                  </td>
                  <td>
                    <DateFormat format="YYYY-MM-DD" value={item.viewed_date} defaultValue="-" />
                  </td>
                  <td>
                    <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
                      <ReportFormatters.ReportDownloadPDFLink
                        type="industry_report"
                        report={item}
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
    </Card>
  );
});

export const ValuationReportTable: React.FC<ReportTableProps> = React.memo(function ValuationReportTable (props: ReportTableProps) {
  const { className } = props;

  const auth = useAuth();

  const query = useQuery<IValuationReport[], Error>({
    enabled: Boolean(auth.customerId),
    queryKey: ['/api/as_customer/valuation_reports'],
  });

  const list = query.data ?? [];

  const error = query.error;

  return (
    <Card className={classNames(className, 'border-0')}>
      <Card.Header className="border border-bottom-0 px-3 py-2">
        <Card.Title as="h5" className="mb-0 p-0 d-flex gap-2 flex-wrap justify-content-between align-items-center">
          Era företagsvärderingar
          <div>
            <RefreshButton className="px-2" onClick={query.refetch} disabled={query.isLoading || query.isRefetching} />
          </div>
        </Card.Title>
      </Card.Header>
      {error && (
        <Card.Body className="border border-bottom-0">
          <ErrorAlert className="mb-0" error={error} />
        </Card.Body>
      )}
      <TableSpinningOverlay isLoading={query.isLoading || query.isRefetching}>
        <div className="table-responsive">
          <Table className="mb-0 border align-middle">
            <thead>
              <tr>
                <th>Skapad</th>
                <th>Företag</th>
                <th>Bokslut</th>
                <th>Hämtad</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {query.isSuccess && (
                <>
                  {list?.length > 0 ? (
                    <>
                      {list.map(item => (
                        <tr key={item.id}>
                          <td>
                            <DateFormat format="YYYY-MM-DD" value={item.created_at} />
                          </td>
                          <td>
                            {item.report_name} ({item.report_org_number})
                          </td>
                          <td>
                            <AccountInterval to={item.report_accountdate} />
                          </td>
                          <td>
                            <DateFormat format="YYYY-MM-DD" value={item.viewed_date} defaultValue="-" />
                          </td>
                          <td>
                            <div className="d-flex gap-1 flex-wrap justify-content-end align-items-center">
                              <ReportFormatters.ReportDownloadPDFLink
                                type="valuation_report"
                                report={item}
                              />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={11} className="p-2">
                        Ni har inga företagsvärderingar här
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </Table>
        </div>
      </TableSpinningOverlay>
    </Card>
  );
});
