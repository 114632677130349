import React from 'react';
import classNames from 'classnames';
import ModalOpeningButton, { ModalOpeningButtonProps } from 'client/buttons/ModalOpeningButton';
import InspectObjectModal from 'client/modals/InspectObjectModal';
import RoleGuard from 'client/guards/RoleGuard';
import { Eye } from 'react-feather';

export interface InspectObjectModalButtonProps<T extends React.ComponentType = React.FC> extends Partial<ModalOpeningButtonProps<T>> {
  object: any;
  iconSize?: number;
}
 
const InspectObjectModalButton = React.memo(function InspectObjectModalButton<T extends React.ComponentType> (props: InspectObjectModalButtonProps<T>) {
  const {
    object,
    size = 'sm',
    iconSize:outerIconSize,
    title = 'Granska objektet',
    className='px-1',
    variant = 'outline-secondary',
    ...restOfProps
  } = props;
  let iconSize = size === 'sm' ? 14 : 18;
  if (typeof outerIconSize === 'number') iconSize = outerIconSize;
  return (
    <RoleGuard role="admin">
      <ModalOpeningButton
        variant={variant}
        className={classNames('d-flex align-items-center', className)}
        title={title}
        size={size}
        {...restOfProps}
        Modal={InspectObjectModal as React.FC}
        modalProps={{object}}
      >
        <Eye size={iconSize} />
      </ModalOpeningButton>
    </RoleGuard>
  );
});

export default InspectObjectModalButton;
