import { useMemo, useState, useEffect } from 'react';
import PageContainer from 'client/components/PageContainer';
import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import PageHeader from 'client/components/PageHeader';
import ErrorAlert from 'client/components/ErrorAlert';
import { ColumnDefinitionFactory, FilterDefinitionFactory } from 'client/table/types';
import useTableState, { OrderDirection } from 'client/hooks/useTableState';
import useTableSelectRows from 'client/hooks/useTableSelectRows';
import * as columnDefs from 'client/table/commonColumnDefinitions';
import * as filterDefs from 'client/table/commonFilterDefinitions';
import { CustomerSurveyWithRelationsRow } from 'client/customerSurvey/types';
import * as CustomerFormatters from 'client/customer/CustomerFormatters';
import { TableSpinningOverlay, TableCardFooterWithPagination } from 'client/table/TableUtils';
import TableVirtual from 'client/table/TableVirtual';
import TableFilterVirtual from 'client/table/TableFilterVirtual';
import * as CustomerSurveyFormatters from 'client/customerSurvey/CustomerSurveyFormatters';
import { TableProvider } from 'client/contexts/TableContext';

export interface CustomerSurveyTableFilterParams {
  id?: string;
  customer_id?: string;
  status?: string;
}

interface ListData {
  rows: CustomerSurveyWithRelationsRow[];
  total_rows: number;
}

const defaultFilterParams = {};

const defaultState = {
  orderBy: 'created_at',
  orderDirection: OrderDirection.DESC,
  ...defaultFilterParams,
};

export default function CustomerSurveyTablePage () {
  const {
    setFilterParams,
    tablePagination,
    tableSort,
    filterParams,
    filterReset,
    params,
  } = useTableState({
    defaultState,
  });

  const query = useQuery<ListData, Error>({
    queryKey: ['/api/customer_surveys/list', params],
    placeholderData: keepPreviousData,
  });

  const rows = query.data?.rows ?? [];

  const [selectedRows, setSelectedRows] = useState<Record<string, boolean>>({});
  const allIds = useMemo(() => rows.map(item => item.id), [rows]);
  const tableSelectRows = useTableSelectRows(allIds, selectedRows, setSelectedRows);

  useEffect(() => {
    tablePagination.onChangeTotalCountOfRows(query.data?.total_rows ?? 0);
  }, [query.data?.total_rows]);

  const columnDefinitions = useMemo(columnDefinitionFactory, []);
  const filterDefinitions = useMemo(filterDefinitionFactory, []);
  const [columnOrder, setColumnOrder] = useState(() => columnDefinitions.filter(c => c.show).map(c => c.id));

  return (
    <PageContainer fluid>
      <TableProvider tableSelectRows={tableSelectRows} tableSort={tableSort}>
        <Helmet>
          <title>Undersökningar</title>
        </Helmet>
        <PageHeader>Undersökningar</PageHeader>
        <ErrorAlert className="my-3" error={query.error} />
        <Card>
          <Card.Header className="p-3">
            <TableFilterVirtual
              isLoading={query.isLoading || query.isRefetching}
              filterDefinitions={filterDefinitions}
              filterParams={filterParams}
              setFilterParams={setFilterParams}

              columnDefinitions={columnDefinitions}
              columnOrder={columnOrder}
              setColumnOrder={setColumnOrder}

              onReload={query.refetch}
              onReset={filterReset}
            />
          </Card.Header>
          <TableSpinningOverlay isLoading={query.isRefetching}>
            <div className="table-responsive">
              <TableVirtual
                className="mb-0 align-middle"
                rows={rows}
                columns={columnDefinitions}
                columnOrder={columnOrder}
                isInitialLoading={query.isLoading}
              />
            </div>
          </TableSpinningOverlay>
          <TableCardFooterWithPagination
            tablePagination={tablePagination}
            rowLength={rows.length}
          /> 
        </Card>
      </TableProvider>
    </PageContainer>
  );
}

const defaultTableColumns = [
  'select',
  'id',
  'status',
  'customer_id',
  'rating',
  'opened_at',
  'answered_at',
  'created_at',
  'actions',
];

const columnSortable = [
  'id',
  'status',
  'customer_id',
  'rating',
  'opened_at',
  'answered_at',
  'created_at',
];

const statuses: CustomerSurveyWithRelationsRow['status'][] = ['unsent', 'sent', 'opened', 'answered', 'expired'];
const statusOptionList = statuses.map(status => ({
  value: status,
  label: <CustomerSurveyFormatters.Status value={status} />,
}));

const filterDefinitionFactory: FilterDefinitionFactory = () => [
  filterDefs.input(['id', 'ID'], {
    extraProps: {placeholder: 'Oavsett ID'},
  }),
  filterDefs.input(['customer_id', 'Kund'], {
    extraProps: {placeholder: 'Oavsett kund-ID'},
  }),
  filterDefs.select(['status', 'Status'], statusOptionList, {
    extraProps: {placeholder: 'Oavsett status'},
  }),
];

const columnDefinitionFactory: ColumnDefinitionFactory<CustomerSurveyWithRelationsRow> = () => [
  columnDefs.select(),
  columnDefs.cell(['id', 'ID'], props => (
    <CustomerSurveyFormatters.CustomerSurveyAnchor value={props.row.id} />
  )),
  columnDefs.cell(['customer_id', 'Kund'], props => (
    <CustomerFormatters.CustomerAnchor value={props.row.customer} />
  )),
  columnDefs.cell(['status', 'Status'], props => (
    <CustomerSurveyFormatters.StatusLabel value={props.row.status} />
  )),
  columnDefs.date(['status_updated_at', 'Statusuppdatering']),
  columnDefs.cell(['rating', 'Betyg'], props => (
    <>{props.row.rating !== null ? `${props.row.rating}/5` : '-'}</>
  )),
  columnDefs.date(['opened_at', 'Öppnad']),
  columnDefs.date(['answered_at', 'Besvarad']),
  columnDefs.date(['created_at', 'Skapad']),
  columnDefs.date(['updated_at', 'Uppdaterad']),

  columnDefs.actions(),
].map(obj => ({
  ...obj,
  show: defaultTableColumns.includes(obj.id),
})).map(obj => columnSortable.includes(obj.id) ? columnDefs.sortable(obj) : obj);
