import React, { useState, useEffect, useCallback, FormEventHandler } from 'react';
import CompanySearch, { useCompanySearchManager } from 'client/components/CompanySearch';
import * as requestCallbacks from 'client/utils/requestCallbacks';
import PageContainer from 'client/components/PageContainer';
import { Card, Form } from 'react-bootstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import LoadingButton from 'client/buttons/LoadingButton';
import axios from 'client/axios';
import ErrorAlert from 'client/components/ErrorAlert';
import { useLocalStorage } from 'client/hooks/useStorage';
import {
  RecallPreviousFormCard,
  SwitchToGroupMotherCompanyAlert,
} from 'client/components/ReportFormHelpers';
import { IValuationReportsGetCompanyInfoData } from 'client/valuationReport/types';
import { ICreateReportQueueData, IReportQueueCreateValuationAndIndustryReportFormData, IValuationAndIndustryReportSettings } from 'client/reportQueue/types';
import { useHistory, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { IIndustryReportsGetCompanyInfoData } from 'client/industryReport/types';
import ReportQueueCreateValuationAndIndustryReportForm, { CreateDestination } from 'client/reportQueue/ReportQueueCreateValuationAndIndustryReportForm';
import PageHeader from 'client/components/PageHeader';

export type GetCompanyInfoData = {
  valuationReport: IValuationReportsGetCompanyInfoData;
  industryReport: IIndustryReportsGetCompanyInfoData;
};

interface PageState {
  orgNumber: string;
  form: IReportQueueCreateValuationAndIndustryReportFormData;
}

interface ReportQueueCreatePageParams {
  orgNumber?: string;
}

interface CreateReportsMutationVars extends PageState {
  destination: CreateDestination;
}

interface CreateReportsMutationData {
  valuationReport: ICreateReportQueueData;
  industryReport: ICreateReportQueueData;
}

export default function ReportQueueCreatePage () {
  const history = useHistory();
  const { orgNumber } = useParams<ReportQueueCreatePageParams>();
  const [pageState, setPageState] = useLocalStorage<PageState | null>('CreateReportPageSavedData', null);
  const [showRecallPrevious, setShowRecallPrevious] = useState(() => (orgNumber && pageState && orgNumber === pageState.form.report_org_number) || (!orgNumber && Boolean(pageState?.form)));
  const companySearchManager = useCompanySearchManager();

  const { data, isLoading, isSuccess, error } = useQuery<GetCompanyInfoData, Error>({
    enabled: Boolean(orgNumber),
    queryKey: ['CreateReportQueueGetCompanyInfo'],
    queryFn: async () => {
      const valuationReport = await axios.get(`/api/valuation_reports/${orgNumber}/company_info`).then(result => result.data);
      const industryReport = await axios.get(`/api/industry_reports/${orgNumber}/company_info`).then(result => result.data);
      return {valuationReport, industryReport};
    },
  });

  useEffect(() => {
    if (!isSuccess) return;

    const fetchedOrgNumber = data.valuationReport.company.org_number || data.industryReport.company.org_number;
    const orgNumberManuallyChanged = pageState && fetchedOrgNumber !== pageState.form.report_org_number;
    const populateEmptyForm = !showRecallPrevious && !pageState;
    if (orgNumberManuallyChanged || populateEmptyForm) {
      const reportSettings: IValuationAndIndustryReportSettings = {
        ...(data.valuationReport.form.report_settings ?? {}),
        ...(data.industryReport.form.report_settings ?? {}),
      };

      const newForm: IReportQueueCreateValuationAndIndustryReportFormData = {
        ...(data.valuationReport.form ?? {}),
        ...(data.industryReport.form ?? {}),
        report_settings: reportSettings,
      };
      setPageState({orgNumber: orgNumber!, form: newForm});
    }
  }, [isLoading, showRecallPrevious, pageState]);

  const createReportsMutation = useMutation<CreateReportsMutationData, Error, CreateReportsMutationVars>({
    mutationKey: ['CreateReportQueue'],
    mutationFn: async vars => {
      const { orgNumber, form } = vars;
      const valuationReport = await axios.post(`/api/valuation_reports/${orgNumber}`, form).then(result => result.data);
      const industryReport = await axios.post(`/api/industry_reports/${orgNumber}`, form).then(result => result.data);
      return {valuationReport, industryReport};
    },
    onSuccess: (results, vars) => {
      setPageState(null);
      requestCallbacks.onSuccess('Företagsvärderingen har skapats');
      requestCallbacks.onSuccess('Branschrapporten har skapats');
      if (vars.destination === 'industryReport') {
        const reportQueueId = results.industryReport.report_queue.id;
        history.push({pathname: `/industry_reports/${reportQueueId}/properties`});
      } else if (vars.destination === 'valuationReport') {
        const reportQueueId = results.valuationReport.report_queue.id;
        history.push({pathname: `/valuation_reports/${reportQueueId}/properties`});
      }
    },
  });

  const onSwapOrgNumber = (orgNumber: string) => {
    history.push({pathname: `/report_queue/create/${orgNumber}`});
    companySearchManager.setValue(orgNumber);
  };

  const onSubmitCreateForm = (destination: CreateDestination) => {
    if (pageState === null || !orgNumber) return;
    createReportsMutation.mutate({orgNumber, form: pageState.form, destination});
  };

  const onSubmitOrgNumberSearchForm: FormEventHandler<HTMLFormElement> = useCallback((ev) => {
    ev.preventDefault();
    const swapToOrgNumber = new FormData(ev.target as HTMLFormElement).get('org_number');
    if (swapToOrgNumber !== null) {
      setShowRecallPrevious(false);
      setPageState(null);
      onSwapOrgNumber(swapToOrgNumber as string);
    }
  }, [setShowRecallPrevious, setPageState, onSwapOrgNumber]);

  const onClickRecall = () => {
    setShowRecallPrevious(false);
    onSwapOrgNumber(pageState!.form.report_org_number);
  };

  const onSetForm: React.Dispatch<React.SetStateAction<PageState['form']>> = useCallback((setState) => {
    setPageState((state: PageState | null) => ({
      orgNumber: orgNumber!,
      form: (typeof setState === 'function' ? setState(state!.form) : setState),
    }));
  }, [setPageState, orgNumber]);

  const { groupStructureGroupMotherCompany } = data?.industryReport ?? data?.valuationReport ?? {};

  return (
    <PageContainer fluid="md">
      <Helmet>
        <title>Ny köad företagsvärdering + branschrapport</title>
      </Helmet>
      <PageHeader>Ny köad företagsvärdering + branschrapport</PageHeader>
      <Card className="mb-4">
        <Form onSubmit={onSubmitOrgNumberSearchForm}>
          <Card.Body>
            <div className="d-flex gap-2 flex-wrap">
              <CompanySearch
                className="w-auto flex-grow-1"
                defaultInputValue={orgNumber}
                {...companySearchManager.props}
              />
              <LoadingButton
                type="submit"
                isLoading={isLoading}
                disabled={!companySearchManager.props.isValid}
              >
                Hämta
              </LoadingButton>
            </div>
            <ErrorAlert className="mt-3 mb-0" error={error} />
          </Card.Body>
        </Form>
      </Card>
      {showRecallPrevious && pageState && (
        <RecallPreviousFormCard
          orgNumber={pageState.form.report_org_number}
          onClickRecall={onClickRecall}
        />
      )}
      {!showRecallPrevious && orgNumber && isSuccess && groupStructureGroupMotherCompany && (
        <SwitchToGroupMotherCompanyAlert
          currentOrgNumber={orgNumber}
          currentCompanyName={data.valuationReport.company?.company_name ?? data.industryReport.company?.company_name ?? ''}
          onSwapOrgNumber={onSwapOrgNumber}
          groupMotherCompany={groupStructureGroupMotherCompany}
        />
      )}
      <ErrorAlert className="mt-3 mb-0" error={error} />
      {!showRecallPrevious && isSuccess && pageState && (
        <ReportQueueCreateValuationAndIndustryReportForm
          data={data}
          form={pageState.form}
          setForm={onSetForm}
          onSubmitForm={onSubmitCreateForm}
          submitError={createReportsMutation.error}
          submitIsLoading={createReportsMutation.isPending}
        />
      )}
    </PageContainer>
  );
}
