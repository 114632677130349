import { useState, useCallback } from 'react';
import { TComponentProps } from 'client/utils/react';

export type UseModalStateProps = Pick<ReturnType<typeof useModalState>, 'show' | 'onHide' | 'onExited'>;

export function useModalState<T extends React.ComponentType, K = TComponentProps<T>> () {
  const [show, setShow] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [props, setProps] = useState<undefined | K>();

  const onToggle = useCallback(() => {
    setShow(v => !v);
    setMounted(v => !v);
  }, [setShow, setMounted]);

  const onShow = useCallback((props?: K) => {
    if (props) setProps(props);
    setShow(true);
    setMounted(true);
  }, [setShow, setMounted]);

  const onHide = useCallback(() => {
    setShow(false);
  }, [setShow]);

  const onExited = useCallback(() => {
    if (props) setProps(undefined);
    setMounted(false);
  }, [setMounted]);

  return {
    onExited,
    mounted,
    show,
    onToggle,
    onShow,
    onHide,
    ...props,
  };
}
