import * as immer from 'immer';
import set from 'lodash/set';
import { useRef } from 'react';

// compatible with second argument to React.memo
export function debugPropsDiff (prevProps: any, nextProps: any) {
  let changed = false;

  for (const key in prevProps) {
    if (prevProps[key] !== nextProps[key]) {
      console.log(`Key changed: ${key} | from: ${prevProps[key]} to: ${nextProps[key]}`); // eslint-disable-line
      changed = true;
    }
  }

  for (const key in nextProps) {
    if (prevProps[key] !== nextProps[key] && !(key in prevProps)) {
      console.log(`Key changed: ${key}`); // eslint-disable-line
      changed = true;
    }
  }

  // If no keys have changed, the result will be true (meaning do not re-render).
  // If any key has changed, the result will be false (meaning do re-render).
  return !changed;
}

/**
 * Helper to detect any changes while hooks/component re-runs
 * Usage: `useDepsDebug(props.columns, props.selectedRows, data.ids)`
 */
export function useDepsDebug (...deps: any[]) {
  const prev = useRef<any[]>([...deps]);
  debugPropsDiff(deps, prev.current);
  prev.current = [...deps];
}

// the return value of this function can be passed into a useState "setValue"
// function and it will update every key with a value using immer and lodash set
export function produceSetMap (updates: Record<string, any>) {
  return immer.produce(draft => {
    for (const path in updates) {
      const value = updates[path];
      set(draft, path, value);
    }
  });
}

export type TComponentProps<T> = T extends
  | React.ComponentType<infer P>
  | React.Component<infer P>
  ? JSX.LibraryManagedAttributes<T, P>
  : never;
