import { BadgeProps } from 'react-bootstrap';
import StatusBadge from 'client/components/StatusBadge';
import { IUser, UserRole as UserRoleType } from 'client/user/types';
import AnchorWithFlag from 'client/anchors/AnchorWithFlag';

interface UserAnchorProps {
  value?: null | string | Partial<IUser>;
  disabled?: boolean;
  inactivated?: boolean;
}

export function UserAnchor (props: UserAnchorProps) {
  const { value, disabled, inactivated } = props;
  if (value === null || typeof value === 'undefined') return null;
  if (typeof value === 'string') {
    return (
      <AnchorWithFlag to={`/user/${value}/form`} disabled={disabled} inactivated={inactivated}>
        {value}
      </AnchorWithFlag>
    );
  }

  const { id, name, inactivated_at } = value as Partial<IUser>;
  return (
    <AnchorWithFlag to={`/user/${id}/form`} inactivated={Boolean(inactivated_at)} disabled={disabled}>
      {name ?? id}
    </AnchorWithFlag>
  );
}

interface UserRoleProps {
  value?: UserRoleType;
}

export function UserRole (props: UserRoleProps) {
  const { value } = props;
  switch (value) {
    default: return <></>;
    case 'admin': return <>Administratör</>;
    case 'account_manager': return <>Account Manager</>;
    case 'coordinator': return <>Koordinator</>;
    case 'team_leader': return <>Team Leader</>;
    case 'noone': return <>Ingen roll</>;
  }
}

interface StatusLabelProps extends BadgeProps {
  value: IUser['inactivated_at'];
}

export function StatusLabel (props: StatusLabelProps) {
  const { value, ...restOfProps } = props;
  return value === null ? (
    <StatusBadge bg="success" {...restOfProps}>Aktiv</StatusBadge>
  ) : (
    <StatusBadge bg="warning" {...restOfProps}>Inaktiverad</StatusBadge>
  );
}
