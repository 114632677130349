import React from 'react';
import useAuth from 'client/hooks/useAuth';
import { AuthUserTypes } from 'client/contexts/AuthContext';

interface UserTypeGuardProps extends React.PropsWithChildren {
  type: AuthUserTypes;
}

export default function UserTypeGuard (props: UserTypeGuardProps) {
  const { children, type } = props;

  const auth = useAuth();
  if (!auth.isUserType(type)) {
    return null;
  }

  return (
    <>
      {children}
    </>
  );
}
